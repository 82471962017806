<script setup lang="ts">
import { computed } from "vue";
import { RouterLink, useRoute } from "vue-router";

import {
  contents,
  Item,
  nextItem,
  prevItem,
  selectedItem,
} from "@/components/docs/contents.ts";
import EndNavigation from "@/components/docs/end-navigation.vue";
import LeftNavigation from "@/components/docs/left-navigation.vue";
import StyledButton from "@/components/form/styled-button.vue";
import CharterIcon from "@/components/icons/charter-icon.vue";
import Scrollview from "@/components/scrollview.vue";
import { ButtonType } from "@/enums/button-type.ts";
import { useAuthStore } from "@/stores/auth.ts";

const authStore = useAuthStore();
const route = useRoute();

const item = computed<Item | undefined>(() => {
  return selectedItem(route, contents);
});
const prev_item = computed<Item | undefined>(() => {
  return item.value ? prevItem(item.value, contents) : undefined;
});
const next_item = computed<Item | undefined>(() => {
  return item.value ? nextItem(item.value, contents) : undefined;
});
</script>
<template>
  <div class="relative flex min-h-screen flex-col pt-[64px]">
    <div
      class="fixed top-0 z-50 flex h-[64px] w-full items-center justify-between border-b-[1px] border-white/10 bg-[#040405] pl-5 pr-8"
    >
      <div class="flex items-center space-x-5">
        <router-link
          :to="{ name: 'docs' }"
          class="flex flex-none items-center space-x-2"
          ><charter-icon class="h-6 w-6"></charter-icon>
          <span class="select-none font-sans text-2xl font-semibold"
            >Charter</span
          >
        </router-link>
      </div>
      <div class="hidden items-center space-x-7 sm:flex">
        <template v-if="!authStore.token">
          <router-link :to="{ name: 'sign-in' }">Sign in</router-link>
          <styled-button :type="ButtonType.PRIMARY" :to="{ name: 'sign-up' }">
            Sign up
          </styled-button>
        </template>
        <template v-else>
          <router-link :to="{ name: 'sign-in' }">Dashboard</router-link>
          <span class="cursor-pointer" @click="authStore.signOut"
            >Sign out</span
          >
        </template>
      </div>
    </div>
    <div class="relative flex grow">
      <div
        class="hidden w-[320px] flex-none border-r-[1px] border-white/10 lg:flex"
      ></div>
      <div
        class="fixed bottom-0 left-0 hidden h-[calc(100vh-64px)] w-[320px] lg:flex"
      >
        <scrollview class="h-full w-full">
          <div class="py-4 pl-5 pr-10">
            <left-navigation></left-navigation>
          </div>
        </scrollview>
      </div>
      <div class="relative flex max-w-[850px] flex-col justify-between">
        <router-view></router-view>
        <end-navigation :prev="prev_item" :next="next_item"></end-navigation>
      </div>
      <div
        class="hidden min-w-[200px] border-l-[1px] border-white/10 p-4 pr-10 xl:flex"
      ></div>
    </div>
  </div>
</template>
