<script setup lang="ts">
import { Crosshair2Icon } from "@radix-icons/vue";
import { computed, PropType, ref } from "vue";

import DialogWrapper from "@/components/dialog-wrapper.vue";
import StyledButton from "@/components/form/styled-button.vue";
import SummaryTable from "@/components/workspace/approval/summary-table.vue";
import BudgetChangeApprovalDialog from "@/dialogs/budget-change-approval.vue";
import { ButtonSize } from "@/enums/button-size.ts";
import { ButtonType } from "@/enums/button-type.ts";
import { targetMetricName } from "@/enums/target-metric.ts";
import { api } from "@/helpers/api.ts";
import { BudgetingRun } from "@/models/budgeting-run.ts";
import { CampaignGroup } from "@/models/campaign-group.ts";
import { WorkspaceRaw } from "@/models/workspace.ts";
import { useWorkspaceStore } from "@/stores/workspace.ts";

const workspaceStore = useWorkspaceStore();

const emit = defineEmits<{
  (e: "approved"): void;
  (e: "rejected"): void;
}>();

const props = defineProps({
  run: {
    type: Object as PropType<BudgetingRun>,
    required: true,
  },
});
const approval_dialog_open = ref<boolean>(false);

const group = computed<CampaignGroup | undefined>(() => {
  if (!workspaceStore.workspace) {
    return undefined;
  }
  for (let i = 0; i < workspaceStore.workspace.campaign_groups.length; i++) {
    if (
      workspaceStore.workspace.campaign_groups[i].id ===
      props.run.campaign_group_id
    ) {
      return workspaceStore.workspace.campaign_groups[i];
    }
  }
  return undefined;
});

const approve = () => {
  if (workspaceStore.workspace == undefined) {
    return;
  }
  for (let i = 0; i < workspaceStore.workspace.campaign_groups.length; i++) {
    for (
      let ii = 0;
      ii < workspaceStore.workspace.campaign_groups[i].runs.length;
      ii++
    ) {
      if (
        workspaceStore.workspace.campaign_groups[i].runs[ii].id === props.run.id
      ) {
        workspaceStore.workspace.campaign_groups[i].runs.splice(ii, 1);
        break;
      }
    }
  }
  emit("approved");

  api
    .put(`budget-change/approve`, {
      run_id: props.run.id,
    })
    .then((workspace_raw: unknown) => {
      workspaceStore.mergeWorkspaceRaw(workspace_raw as WorkspaceRaw);
    });
};
const reject = () => {
  if (workspaceStore.workspace == undefined) {
    return;
  }
  for (let i = 0; i < workspaceStore.workspace.campaign_groups.length; i++) {
    for (
      let ii = 0;
      ii < workspaceStore.workspace.campaign_groups[i].runs.length;
      ii++
    ) {
      if (
        workspaceStore.workspace.campaign_groups[i].runs[ii].id === props.run.id
      ) {
        workspaceStore.workspace.campaign_groups[i].runs.splice(ii, 1);
        break;
      }
    }
  }
  emit("rejected");

  api.put(`budget-change/reject`, {
    run_id: props.run.id,
  });
};
</script>
<template>
  <div
    class="w-full rounded-[10px] border-[10px] border-white/10 px-4 pb-3.5 pt-3"
    v-if="group"
  >
    <div class="flex justify-between pb-5">
      <div class="flex items-center space-x-6">
        <span class="relative -top-[2px] text-xl" v-if="group.name">{{
          group.name
        }}</span>
        <div class="flex items-center space-x-1.5 font-mono text-sm opacity-50">
          <Crosshair2Icon class="h-4 w-4"></Crosshair2Icon>
          <span>{{ targetMetricName(group.target_metric) }}</span>
        </div>
      </div>
      <div class="flex items-center space-x-3">
        <styled-button
          :type="ButtonType.REGULAR"
          :size="ButtonSize.SMALL"
          @click="approval_dialog_open = true"
          >Review</styled-button
        >
      </div>
    </div>
    <div class="pb-6">
      <summary-table :run="props.run"></summary-table>
    </div>
    <div class="flex items-end justify-between">
      <span class="text-sm font-light opacity-50"
        >{{ props.run.changes.length }} campaign{{
          props.run.changes.length >= 2 ? `s` : ``
        }}
        will have their budgets changed</span
      >
      <div class="flex items-center space-x-4">
        <styled-button
          :type="ButtonType.REGULAR"
          :size="ButtonSize.SMALL"
          @click="reject"
          >Reject</styled-button
        >
        <styled-button
          :type="ButtonType.PRIMARY"
          :size="ButtonSize.SMALL"
          @click="approve"
          >Approve</styled-button
        >
      </div>
    </div>
    <dialog-wrapper v-model="approval_dialog_open">
      <budget-change-approval-dialog
        :run="run"
        @close="approval_dialog_open = false"
        @approved="
          () => {
            emit('approved');
            approval_dialog_open = false;
          }
        "
        @rejected="
          () => {
            emit('rejected');
            approval_dialog_open = false;
          }
        "
      ></budget-change-approval-dialog>
    </dialog-wrapper>
  </div>
</template>
