<script setup lang="ts">
import {
  ScrollAreaRoot,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport,
} from "radix-vue";
import { computed, ref } from "vue";
const emit = defineEmits<{
  (e: "scroll"): void;
}>();

const viewport = ref();
const viewportElement = computed(() => {
  return viewport.value?.viewportElement;
});

defineExpose({
  viewportElement,
});
</script>
<template>
  <ScrollAreaRoot
    class="h-0 grow overflow-hidden"
    style="--scrollbar-size: 10px"
  >
    <ScrollAreaViewport
      ref="viewport"
      class="h-full w-full"
      @scroll="emit('scroll')"
    >
      <slot></slot>
    </ScrollAreaViewport>
    <ScrollAreaScrollbar
      class="flex touch-none select-none p-0.5 transition-colors ease-out data-[orientation=horizontal]:h-2.5 data-[orientation=vertical]:w-2 data-[orientation=horizontal]:flex-col"
      orientation="vertical"
    >
      <ScrollAreaThumb
        class="relative flex-1 rounded-sm bg-zinc-900 before:absolute before:left-1/2 before:top-1/2 before:h-full before:min-h-[44px] before:w-full before:min-w-[44px] before:-translate-x-1/2 before:-translate-y-1/2 before:content-['']"
      />
    </ScrollAreaScrollbar>
    <ScrollAreaScrollbar
      class="flex touch-none select-none p-0.5 transition-colors ease-out data-[orientation=horizontal]:h-2.5 data-[orientation=vertical]:w-2 data-[orientation=horizontal]:flex-col"
      orientation="horizontal"
    >
      <ScrollAreaThumb
        class="relative flex-1 rounded-[10px] before:absolute before:left-1/2 before:top-1/2 before:h-full before:min-h-[44px] before:w-full before:min-w-[44px] before:-translate-x-1/2 before:-translate-y-1/2 before:content-['']"
      />
    </ScrollAreaScrollbar>
  </ScrollAreaRoot>
</template>
