<script setup lang="ts">
import { PropType, ref } from "vue";

import DialogBody from "@/components/dialog-body.vue";
import DialogTitlebar from "@/components/dialog-titlebar.vue";
import StyledButton from "@/components/form/styled-button.vue";
import CampaignsTable from "@/components/workspace/approval/campaigns-table.vue";
import SummaryTable from "@/components/workspace/approval/summary-table.vue";
import { ButtonSize } from "@/enums/button-size.ts";
import { ButtonType } from "@/enums/button-type.ts";
import { api } from "@/helpers/api.ts";
import { BudgetingRun } from "@/models/budgeting-run.ts";
import { WorkspaceRaw } from "@/models/workspace.ts";
import { useWorkspaceStore } from "@/stores/workspace.ts";

const emit = defineEmits<{
  (e: "approved"): void;
  (e: "rejected"): void;
  (e: "close"): void;
}>();

const props = defineProps({
  run: {
    type: Object as PropType<BudgetingRun>,
    required: true,
  },
});
const workspaceStore = useWorkspaceStore();

const submitting = ref<boolean>(false);

const approve = () => {
  if (workspaceStore.workspace == undefined) {
    return;
  }
  if (workspaceStore.workspace.id == 1) {
    return;
  }
  for (let i = 0; i < workspaceStore.workspace.campaign_groups.length; i++) {
    for (
      let ii = 0;
      ii < workspaceStore.workspace.campaign_groups[i].runs.length;
      ii++
    ) {
      if (
        workspaceStore.workspace.campaign_groups[i].runs[ii].id === props.run.id
      ) {
        workspaceStore.workspace.campaign_groups[i].runs.splice(ii, 1);
        break;
      }
    }
  }
  emit("approved");

  api
    .put(`budget-change/approve`, {
      run_id: props.run.id,
    })
    .then((workspace_raw: unknown) => {
      workspaceStore.mergeWorkspaceRaw(workspace_raw as WorkspaceRaw);
    });
};
const reject = () => {
  if (workspaceStore.workspace == undefined) {
    return;
  }
  if (workspaceStore.workspace.id == 1) {
    return;
  }
  for (let i = 0; i < workspaceStore.workspace.campaign_groups.length; i++) {
    for (
      let ii = 0;
      ii < workspaceStore.workspace.campaign_groups[i].runs.length;
      ii++
    ) {
      if (
        workspaceStore.workspace.campaign_groups[i].runs[ii].id === props.run.id
      ) {
        workspaceStore.workspace.campaign_groups[i].runs.splice(ii, 1);
        break;
      }
    }
  }
  emit("rejected");

  api.put(`budget-change/reject`, {
    run_id: props.run.id,
  });
};
</script>
<template>
  <div>
    <dialog-titlebar
      @close="emit('close')"
      title="Review budget change"
      :title-border="true"
      :padding="false"
    >
      <template #right>
        <div class="flex items-center space-x-3.5 pl-8">
          <styled-button
            :size="ButtonSize.SMALL"
            :type="ButtonType.REGULAR"
            :submitting="submitting"
            @click="reject"
            >Reject</styled-button
          >
          <styled-button
            :size="ButtonSize.SMALL"
            :type="ButtonType.PRIMARY"
            :submitting="submitting"
            @click="approve"
            >Approve</styled-button
          >
        </div>
      </template>
    </dialog-titlebar>
    <dialog-body :padding="false">
      <div class="px-5 pb-5 pt-4">
        <summary-table :run="props.run"></summary-table>
      </div>
      <div
        class="flex min-w-[840px] flex-col space-y-4 border-t border-white/10 px-5 py-3.5"
      >
        <!--        <span class="font-light">Campaigns</span>-->
        <campaigns-table :run="props.run"></campaigns-table>
      </div>
    </dialog-body>
  </div>
</template>
