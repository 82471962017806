import { Capacitor } from "@capacitor/core";
import { createRouter, createWebHistory } from "vue-router";

import DocsBillingPage from "@/components/docs/account-management/billing.vue";
import DocsTeamAccessPage from "@/components/docs/account-management/team-access.vue";
import DocsWorkspacesPage from "@/components/docs/account-management/workspaces.vue";
import DocsBudgetChangeApprovalPage from "@/components/docs/core-concepts/budget-change-approval.vue";
import DocsCampaignGroupsPage from "@/components/docs/core-concepts/campaign-groups.vue";
import DocsCampaignThresholdsPage from "@/components/docs/core-concepts/campaign-thresholds.vue";
import DocsTargetMetricsPage from "@/components/docs/core-concepts/target-metrics.vue";
import DocsIntroductionPage from "@/components/docs/introduction/introduction.vue";
import DocsAssigningCampaignsPage from "@/components/docs/onboarding/assigning-campaigns.vue";
import DocsCampaignDashboardPage from "@/components/docs/onboarding/campaign-dashboard.vue";
import DocsConnectingAdChannelsPage from "@/components/docs/onboarding/connecting-ad-channels.vue";
import DocsCreatingAnAccountPage from "@/components/docs/onboarding/creating-an-account.vue";
import DocsCreatingCampaignGroupsPage from "@/components/docs/onboarding/creating-campaign-groups.vue";
import Error404 from "@/components/error/404.vue";
import RoadmapPage from "@/components/landing/roadmap/page.vue";
import WorkspaceLayout from "@/layouts/workspace.vue";
import AdChannelsPage from "@/pages/app/workspace/ad-channel.vue";
import ApprovalPage from "@/pages/app/workspace/approval.vue";
import CampaignsPage from "@/pages/app/workspace/campaigns.vue";
import WorkspacesPage from "@/pages/app/workspaces.vue";
import SignInPage from "@/pages/auth/sign-in.vue";
import SignInEmailPage from "@/pages/auth/sign-in-with-email.vue";
import SignUpPage from "@/pages/auth/sign-up.vue";
import SignUpEmailPage from "@/pages/auth/sign-up-with-email.vue";
import DocsLayout from "@/pages/docs.vue";
import MarketingPage from "@/pages/index.vue";
import TermsPage from "@/pages/legal/accept-terms.vue";
import PrivacyPolicyPage from "@/pages/legal/privacy-policy.vue";
import TermsAndConditionsPage from "@/pages/legal/terms-of-service.vue";
import OAuthGoogleAdChannel from "@/pages/oauth/google-ad-channel.vue";
import OAuthGoogle from "@/pages/oauth/google-auth.vue";
import OAuthMetaAdChannel from "@/pages/oauth/meta-ad-channel.vue";
import OAuthMeta from "@/pages/oauth/meta-auth.vue";
import { useAuthStore } from "@/stores/auth.ts";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "home",
      component: MarketingPage,
      meta: { tidio: true },
      children: [
        {
          path: "/privacy-policy",
          name: "privacy",
          component: PrivacyPolicyPage,
        },
        {
          path: "/terms-of-service",
          name: "terms-of-service",
          component: TermsAndConditionsPage,
        },
        {
          path: "/road-map",
          name: "roadmap",
          component: RoadmapPage,
        },
      ],
    },
    {
      path: "/docs",
      name: "docs",
      component: DocsLayout,
      meta: { tidio: false },
      children: [
        {
          path: "introduction",
          name: "docs-introduction",
          component: DocsIntroductionPage,
        },
        {
          path: "campaign-groups",
          name: "docs-campaign-groups",
          component: DocsCampaignGroupsPage,
        },
        {
          path: "target-metrics",
          name: "docs-target-metrics",
          component: DocsTargetMetricsPage,
        },
        {
          path: "campaign-thresholds",
          name: "docs-campaign-thresholds",
          component: DocsCampaignThresholdsPage,
        },
        {
          path: "budget-change-approval",
          name: "docs-budget-change-approval",
          component: DocsBudgetChangeApprovalPage,
        },
        {
          path: "campaign-dashboard",
          name: "docs-campaign-dashboard",
          component: DocsCampaignDashboardPage,
        },
        {
          path: "creating-an-account",
          name: "docs-creating-an-account",
          component: DocsCreatingAnAccountPage,
        },
        {
          path: "connecting-ad-channels",
          name: "docs-connecting-ad-channels",
          component: DocsConnectingAdChannelsPage,
        },
        {
          path: "creating-campaign-groups",
          name: "docs-creating-campaign-groups",
          component: DocsCreatingCampaignGroupsPage,
        },
        {
          path: "assigning-workspace",
          name: "docs-assigning-workspace",
          component: DocsAssigningCampaignsPage,
        },
        {
          path: "workspaces",
          name: "docs-workspaces",
          component: DocsWorkspacesPage,
        },
        {
          path: "team-access",
          name: "docs-team-access",
          component: DocsTeamAccessPage,
        },
        {
          path: "billing",
          name: "docs-billing",
          component: DocsBillingPage,
        },
      ],
    },
    {
      path: "/oauth",
      children: [
        {
          path: "meta",
          name: "oauth-meta",
          component: OAuthMeta,
        },
        {
          path: "google",
          name: "oauth-google",
          component: OAuthGoogle,
        },
        {
          path: "meta-ad-channel",
          name: "oauth-meta-ad-channel",
          component: OAuthMetaAdChannel,
          meta: { title: "Meta Ad Channel", authenticated: true },
        },
        {
          path: "google-ad-channel",
          name: "oauth-google-ad-channel",
          component: OAuthGoogleAdChannel,
          meta: { title: "Google Channel", authenticated: true },
        },
      ],
    },
    {
      path: "/sign-in",
      name: "sign-in",
      component: SignInPage,
      meta: { title: "Sign in", unauthenticated: true },
    },
    {
      path: "/sign-in/email",
      name: "sign-in-with-email",
      component: SignInEmailPage,
      meta: { title: "Sign in", unauthenticated: true },
    },
    {
      path: "/sign-up",
      name: "sign-up",
      component: SignUpPage,
      meta: { title: "Sign up", unauthenticated: true },
    },
    {
      path: "/sign-up/email",
      name: "sign-up-with-email",
      component: SignUpEmailPage,
      meta: { title: "Sign up", unauthenticated: true },
    },
    {
      path: "/workspaces",
      name: "workspaces",
      component: WorkspacesPage,
      meta: { title: "Workspaces", authenticated: true },
    },
    {
      path: "/terms",
      name: "terms",
      component: TermsPage,
      meta: { title: "Terms", authenticated: true },
    },
    {
      path: "/:workspace(\\d+)",
      name: "workspace",
      component: WorkspaceLayout,
      meta: { authenticated: true, tidio: true },
      redirect: (route) => {
        return {
          name: "campaigns",
          params: { workspace: route.params.workspace },
        };
      },
      children: [
        {
          path: "campaigns",
          name: "campaigns",
          component: CampaignsPage,
          meta: { title: "Campaigns" },
        },
        {
          path: "ad-channels",
          name: "ad-channels",
          component: AdChannelsPage,
          meta: { title: "Ad channels" },
        },
        {
          path: "approval",
          name: "approval",
          component: ApprovalPage,
          meta: { title: "Approval" },
        },
      ],
    },
    {
      path: "/:notFound(.*)",
      component: Error404,
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    from.meta.x = 1; // todo: remove this, its just for typescript
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
        top: 180,
      };
    }
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});
router.beforeEach((to, from) => {
  const authStore = useAuthStore();

  // redirect authenticated home to workspaces for apps
  if (Capacitor.isNativePlatform() && to.name === "home" && authStore.token) {
    return {
      name: "workspaces",
    };
  }

  // redirect unauthenticated pages to dashboard if authenticated
  if (to.meta.unauthenticated && authStore.token) {
    return {
      name: "workspaces",
    };
  }

  // redirect authenticated pages to sign in if unauthenticated
  if (to.meta.authenticated && !authStore.token) {
    return {
      name: "sign-in",
      query: {
        to: btoa(JSON.stringify(to.fullPath)),
      },
    };
  }

  // // redirect workspace selector page to default workspace or invite page if appropriate.
  // // if not initialised yet, continue to the workspaces page, it can redirect itself once initialisation is complete
  // if (to.name === "workspaces" && authStore.user && workspaceStore.workspaces) {
  //   const redirect = workspacesRedirect(
  //     authStore.user.id,
  //     workspaceStore.workspaces,
  //   );
  //
  //   if (redirect) {
  //     return redirect;
  //   }
  // }
  // title
  document.title = to.meta.title
    ? `${to.meta.title as string} - AI-powered ad budgeting`
    : "Charter - AI-powered ad budgeting";

  // legal
  if (to.matched[0].name === "home") {
    document.body.classList.add("landing");
  } else {
    document.body.classList.remove("landing");
  }
  // tidio
  if (to.meta.tidio === true) {
    document.body.classList.add("tidio");
  } else {
    document.body.classList.remove("tidio");
  }

  window.dispatchEvent(new CustomEvent("navigating", { detail: { to, from } }));
});
router.afterEach((to, from) => {
  window.dispatchEvent(new CustomEvent("navigated", { detail: { to, from } }));
});
export default router;

// export const workspacesRedirect = (
//   user_id: number,
//   workspaces: Workspace[],
// ) => {
//   // const workspaceStore = useWorkspaceStore();
//   //
//   // if (workspaceStore.acceptedCount! === 1) {
//   //
//   //   return {
//   //     name: "workspace",
//   //     params: {
//   //       workspace: workspaces[0].id,
//   //     },
//   //   };
//   // } else if (
//   //   workspaceStore.acceptedCount! === 0 &&
//   //   workspaceStore.invitationCount! === 1
//   // ) {
//   //   return {
//   //     name: "invite",
//   //     params: {
//   //       workspace: workspaces[0].id,
//   //     },
//   //   };
//   // } else if (
//   //   workspaceStore.acceptedCount! === 0 &&
//   //   workspaceStore.invitationCount! >= 2
//   // ) {
//   //   return {
//   //     name: "invites",
//   //   };
//   // } else if (
//   //   workspaceStore.acceptedCount! === 0 &&
//   //   workspaceStore.invitationCount! === 0
//   // ) {
//   //   // todo: special screen for this - option to create a new workspace
//   // }
// };
