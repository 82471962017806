<script setup lang="ts">
import {
  PopoverArrow,
  PopoverContent,
  PopoverPortal,
  PopoverRoot,
  PopoverTrigger,
} from "radix-vue";

const props = defineProps({
  magic: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const open = defineModel<boolean>();
</script>

<template>
  <PopoverRoot v-model:open="open">
    <PopoverTrigger aria-label="Update dimensions">
      <slot name="trigger"></slot>
    </PopoverTrigger>
    <PopoverPortal :disabled="true">
      <PopoverContent
        side="bottom"
        :side-offset="5"
        :class="`popover data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade ${props.magic ? `magic` : ``} relative mx-5 w-[270px] rounded-sm border border-zinc-200 bg-white shadow-[0_3px_10px_0px_rgba(0,0,0,0.05)] will-change-[transform,opacity] dark:border-zinc-800 dark:bg-black`"
      >
        <div class="rounded-sm bg-white px-3.5 pb-3.5 pt-2 dark:bg-black">
          <slot name="content"></slot>
        </div>
        <PopoverArrow as-child>
          <div
            class="relative -top-0 h-[6px] w-[12px] overflow-hidden shadow-[0_0_20px_5px_rgba(255,255,255,0.8)] dark:shadow-none"
          >
            <div
              :class="`relative -top-[6px] h-[10px] w-[10px] rotate-45 border bg-white dark:bg-black ${props.magic ? 'border-[#bf5eff]' : 'border-zinc-200 dark:border-zinc-700'} shadow-[0_0_20px_5px_rgba(255,255,255,0.8)] dark:shadow-none`"
            ></div>
          </div>
        </PopoverArrow>
      </PopoverContent>
    </PopoverPortal>
  </PopoverRoot>
</template>
