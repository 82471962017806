<script setup lang="ts">
import { PropType } from "vue";
import { RouteLocationRaw } from "vue-router";

import GoogleIcon from "@/components/icons/google-icon.vue";
import MetaIcon from "@/components/icons/meta-icon.vue";
import { AuthContext, authContextLabel } from "@/enums/auth-context.ts";

const props = defineProps({
  authContext: {
    type: String as PropType<AuthContext>,
    required: true,
  },
  emailAuthRoute: {
    type: Object as PropType<RouteLocationRaw>,
    required: true,
  },
});

const loginWithGoogle = () => {
  window.location.href =
    "https://accounts.google.com/o/oauth2/v2/auth?" +
    `client_id=${import.meta.env.VITE_GOOGLE_CLIENT_ID}` +
    `&redirect_uri=${window.location.origin}/oauth/google` +
    `&response_type=code` +
    `&access_type=online` +
    `&enable_granular_consent=false` +
    `&prompt=select_account consent` +
    `&scope=https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile` +
    `&state=${props.authContext}`;
};
const loginWithMeta = () => {
  window.location.href =
    "https://www.facebook.com/v21.0/dialog/oauth?" +
    `client_id=${import.meta.env.VITE_META_APP_ID}` +
    `&redirect_uri=${window.location.origin}/oauth/meta` +
    `&config_id=${import.meta.env.VITE_META_AUTH_CONFIG_ID}` +
    `&state=${props.authContext}`;
};
</script>
<template>
  <div class="">
    <div class="flex flex-col">
      <!--      <span class="w-full pb-6 text-center font-sans text-lg font-medium"-->
      <!--        >{{ authContextLabel(props.authContext) }} to your account</span-->
      <!--      >-->
      <div class="flex flex-col space-y-4">
        <button
          @click="loginWithGoogle"
          :class="`button large space-x-3.5 rounded-sm`"
        >
          <google-icon class="h-6 w-6" />
          <span class="text-nowrap text-base"
            >{{ authContextLabel(props.authContext) }} with Google</span
          >
        </button>
        <button
          @click="loginWithMeta"
          :class="`button large space-x-3.5 rounded-sm`"
        >
          <meta-icon class="h-6 w-6" />
          <span class="text-nowrap text-base"
            >{{ authContextLabel(props.authContext) }} with Meta</span
          >
        </button>
        <router-link
          :to="props.emailAuthRoute"
          :class="`button large space-x-3.5 rounded-sm`"
        >
          <span class="text-nowrap text-base"
            >{{ authContextLabel(props.authContext) }} with Email</span
          >
        </router-link>
      </div>
    </div>
  </div>
</template>
