<script setup lang="ts">
import { computed, PropType } from "vue";

import Graph from "@/components/workspace/campaigns/metrics/graph.vue";
import { MetricClass } from "@/enums/metric-class.ts";
import { Campaign } from "@/models/campaign.ts";
import { DataPoint, DataSeries } from "@/models/data.ts";
import { useCurrencyStore } from "@/stores/currency.ts";
import { useWorkspaceStore } from "@/stores/workspace.ts";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  campaign: {
    type: Object as PropType<Campaign>,
    required: true,
  },
  metric: {
    type: String,
    required: true,
  },
  metricClass: {
    type: String as PropType<MetricClass>,
    required: false,
    default: MetricClass.INTEGER,
  },
});
const currencyStore = useCurrencyStore();
const workspaceStore = useWorkspaceStore();

const format = (value: number | undefined): string => {
  if (!workspaceStore.workspace) {
    return ``;
  }
  if (value === undefined) {
    return `–`;
  } else if (props.metricClass === MetricClass.INTEGER) {
    return `${Math.round(value).toLocaleString()}`;
  } else if (props.metricClass === MetricClass.FLOAT_1DP) {
    return `${(Math.round((value + Number.EPSILON) * 10) / 10).toLocaleString()}`;
  } else if (props.metricClass === MetricClass.FLOAT_2DP) {
    return `${(Math.round((value + Number.EPSILON) * 100) / 100).toLocaleString()}`;
  } else if (props.metricClass === MetricClass.FLOAT_3DP) {
    return `${(Math.round((value + Number.EPSILON) * 1000) / 1000).toLocaleString()}`;
  } else if (props.metricClass === MetricClass.FLOAT_4DP) {
    return `${(Math.round((value + Number.EPSILON) * 10000) / 10000).toLocaleString()}`;
  } else if (props.metricClass === MetricClass.PERCENT) {
    return `${(Math.round((value * 100 + Number.EPSILON) * 100) / 100).toLocaleString()}%`;
  } else if (props.metricClass === MetricClass.CURRENCY) {
    return currencyStore.format(
      value,
      workspaceStore.workspace.currency.id,
      props.campaign.currency_id,
    );
  }

  return `${value}`;
};

const positive = computed<boolean>(() => {
  return true;
});

const value = computed<number | undefined>(() => {
  return props.campaign[`${props.metric}_yesterday`];
});

const history = computed<DataSeries | undefined>(() => {
  if (!props.campaign || !workspaceStore.workspace) {
    return undefined;
  }
  return {
    name: props.name,
    values: props.campaign.time_series.map((item) => {
      const item_time = item.time;
      let date = new Date(item_time);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
      date = new Date(date.getTime() - 43200000);

      return {
        date: date,
        value: item[`${props.metric}_yesterday`],
      } as DataPoint;
    }) as DataPoint[],
    formatter: (value: number) => {
      return currencyStore.format(
        value,
        workspaceStore.workspace!.currency.id,
        props.campaign.currency_id,
      );
    },
  } as DataSeries;
});
</script>
<template>
  <div
    v-if="props.campaign && workspaceStore.workspace"
    class="campaign dark:border-1 group relative flex aspect-[10/4] flex-col overflow-hidden rounded-xl border border-zinc-200 outline transition-all duration-300 dark:border-none dark:bg-black dark:outline-4 dark:outline-[#0c0e11] dark:hover:outline-[#141517]"
  >
    <graph
      v-if="history?.values.length"
      class="absolute inset-x-0 bottom-0 w-full"
      :data="history"
      :uuid="`metric-block-${props.metric}`"
    />
    <div
      class="pointer-events-none absolute z-10 flex items-center justify-between pl-1.5 pt-2"
    >
      <div
        class="flex items-center space-x-3.5 text-base leading-5 text-zinc-600 transition-all duration-300 group-hover:text-zinc-300 sm:text-base sm:leading-none"
      >
        <span class="rounded-md bg-black px-2 py-1 font-mono">{{
          props.name
        }}</span>
      </div>
    </div>
    <div
      :class="`pointer-events-none z-10 flex grow items-center justify-center space-x-1.5 px-5 pb-2 pt-4 font-mono text-3xl md:text-4xl lg:h-auto xl:text-5xl ${positive ? `Xtext-emerald-300 text-white` : `text-rose-400`}`"
    >
      <span :class="value === undefined ? `opacity-30` : ``">{{
        format(value)
      }}</span>
      <!--      <TriangleUpIcon-->
      <!--        v-if="props.positive"-->
      <!--        class="h-6 w-auto text-emerald-300 opacity-70"-->
      <!--      ></TriangleUpIcon>-->
      <!--      <TriangleDownIcon-->
      <!--        v-else-->
      <!--        class="h-6 w-auto text-rose-400 opacity-70"-->
      <!--      ></TriangleDownIcon>-->
    </div>
    <!--    <pre-->
    <!--      class="text-xs"-->
    <!--      v-for="campaign in props.campaign.workspace"-->
    <!--      :key="campaign.id"-->
    <!--      >{{ campaign.metrics }}</pre-->
    <!--    >-->
  </div>
</template>
