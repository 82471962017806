<script setup lang="ts">
import { watchOnce } from "@vueuse/core";
import { useRoute, useRouter } from "vue-router";

import LoadingBar from "@/components/loading-bar.vue";
import { AuthContext, authContextRoute } from "@/enums/auth-context.ts";
import { api } from "@/helpers/api.ts";
import {
  AuthenticationResult,
  AuthenticationSuccess,
} from "@/models/authentication-result.ts";
import { useAuthStore } from "@/stores/auth.ts";
import { useInternationalisationStore } from "@/stores/internationalisation.ts";

const route = useRoute();
const router = useRouter();
const internationalisationStore = useInternationalisationStore();
const authStore = useAuthStore();

if (route.query.error) {
  if (route.query.state) {
    router.replace(authContextRoute(route.query.state as AuthContext));
  } else {
    router.replace({
      name: "home",
    });
  }
}

const exchangeCodeForToken = async () => {
  if (route.query.error) {
    return;
  }
  const code = route.query.code;

  api
    .put("auth/google", {
      code,
      redirect_uri: `${window.location.origin}/oauth/google`,
      language_id: internationalisationStore.language!.id,
      country_id: internationalisationStore.country!.id,
      client: localStorage.client,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    })
    .then((result: unknown) => {
      if ((result as AuthenticationResult).success) {
        authStore.signIn(result as AuthenticationSuccess).then(() => {
          router.replace({
            name: "workspaces",
          });
        });
      }
    });
};

const exchangeForTokenWhenReady = (): boolean => {
  if (
    !!internationalisationStore.language &&
    !!internationalisationStore.country
  ) {
    exchangeCodeForToken();
    return true;
  } else {
    return false;
  }
};

if (!exchangeForTokenWhenReady()) {
  watchOnce(
    () => internationalisationStore.language,
    exchangeForTokenWhenReady,
  );
  watchOnce(() => internationalisationStore.country, exchangeForTokenWhenReady);
}

if (
  !!internationalisationStore.language &&
  !!internationalisationStore.country
) {
  exchangeCodeForToken();
}
</script>
<template>
  <div class="flex grow items-center justify-center">
    <loading-bar></loading-bar>
  </div>
</template>
