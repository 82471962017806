import { defineStore } from "pinia";
import { ref } from "vue";

import { nullsToUndefined } from "@/helpers/nulls-to-undefined.ts";
import { Timezone, TimezoneRaw } from "@/models/timezone.ts";

export const useTimezoneStore = defineStore("timezone", () => {
  const timezones = ref<Timezone[] | undefined>(undefined);

  const load = (timezones_: TimezoneRaw[]) => {
    timezones.value = timezones_.map((timezone_raw: TimezoneRaw) => {
      return nullsToUndefined({
        ...timezone_raw,
      }) as Timezone;
    });
  };
  const clear = () => {
    timezones.value = undefined;
  };

  const find = (timezone_id: number): Timezone | undefined => {
    return timezones.value!.find(
      (timezone: Timezone) => timezone.id === timezone_id,
    );
  };

  return {
    timezones,
    load,
    find,
    clear,
  };
});
