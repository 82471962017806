<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";

import create_campaign_group from "@/assets/docs/create-campaign-group.png";
import { contents, Item, selectedItem } from "@/components/docs/contents.ts";
import LearnMoreAbout from "@/components/docs/learn-more-about.vue";

const route = useRoute();

const page = computed<Item | undefined>(() => selectedItem(route, contents));
</script>
<template>
  <div v-if="page" class="prose prose-invert py-4 pl-5 pr-7">
    <h1>{{ page.title }}</h1>
    <p class="text-xl">
      Campaign groups in Charter are how you group your ad campaigns according
      to different objectives you may have for them.
    </p>
  </div>
  <div class="prose-invert flow-root">
    <div
      class="border-l-4 border-[#bf5eff] bg-purple-50 p-4 text-purple-800"
      role="alert"
    >
      <p class="font-bold">Example</p>
      <p>
        You're running a number of ads across Meta and Google with the objective
        of brand awareness, your target metric is Reach and you set a shared
        budget of $2,000/month.
      </p>
    </div>
  </div>
  <div v-if="page" class="prose prose-invert py-4 pl-5 pr-7">
    <learn-more-about
      :route="{
        name: 'docs-campaign-groups',
      }"
      >Campaign Groups</learn-more-about
    >
    <div class="rounded-md border border-white/30 pl-8 pr-8">
      <img class="object-cover" :src="create_campaign_group" />
    </div>
    <p>
      To create a campaign group, click the “create campaign group” button in
      the top right corner of your dashboard. Input your chosen monthly spend,
      select your target metric, and optionally give the campaign group a name
      e.g. “Brand awareness”. Then click “create campaign group”.
    </p>
    <p>
      Within the campaign group settings, you can also set
      <a href="https://usecharter.io/docs/campaign-thresholds"> Thresholds</a>
      and adjust
      <a href="https://usecharter.io/docs/budget-change-approval"> Approval</a>
      settings.
    </p>
  </div>
</template>
