<script setup lang="ts">
import * as d3 from "d3";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import {
  computed,
  onMounted,
  onUnmounted,
  onUpdated,
  PropType,
  ref,
} from "vue";

import { DataPoint, DataSeries } from "@/models/data.ts";

dayjs.extend(advancedFormat);

const props = defineProps({
  data: {
    type: Object as PropType<DataSeries>,
    required: true,
  },
  uuid: {
    type: String,
    required: true,
  },
});
const chartContainer = ref<HTMLElement | undefined>();

const render = () => {
  if (chartContainer.value) {
    // Set up margins and dimensions
    const margin = { top: 0, right: 0, bottom: 0, left: 0 };
    const width = chartContainer.value.offsetWidth - margin.left - margin.right;
    const height =
      chartContainer.value.offsetHeight - margin.top - margin.bottom;
    // Remove existing SVG
    d3.select(chartContainer.value).select("svg").remove();
    // Create SVG element
    const svg = d3
      .select(chartContainer.value)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);
    // Add a group for the chart area
    const chartGroup = svg
      .append("g")
      .attr(
        "transform",
        `translate(${margin.left},${margin.top + (height / 9) * 7})`,
      );
    // Define the scales
    const xScale = d3
      .scaleTime()
      .domain([start.value, end.value])
      .range([0, width]);
    const yScale_budget = d3
      .scaleLinear()
      .domain([
        d3.min([...props.data.values.map((item) => item.value)])!,
        d3.max([...props.data.values.map((item) => item.value)])!,
      ])
      .nice()
      .range([(height / 9) * 2, 0]);
    // Append axes
    const gX = chartGroup
      .append("g")
      .attr("class", "text-zinc-600")
      .attr("transform", `translate(0,${height})`)
      .call(
        d3
          .axisBottom<Date>(xScale)
          .ticks(d3.timeDay.every(1))
          .tickFormat((date: Date): string => {
            const formatDay = d3.timeFormat("%A");
            return formatDay(date).charAt(0);
          }),
      );
    gX.select(".domain").remove();
    gX.selectAll(".tick  line").remove();

    // // Add vertical gridlines
    // chartGroup
    //   .selectAll("xGrid")
    //   .data(
    //     d3.timeDay.range(start.value.toDate(), end.value.toDate())
    //   )
    //   .join("line")
    //   .attr("x1", (d: Date) => xScale(d))
    //   .attr("x2", (d: Date) => xScale(d))
    //   .attr("y1", 0)
    //   .attr("y2", height)
    //   .attr("stroke", "#1c1c1c")
    //   .attr("stroke-width", 1);

    // Draw lines
    const line_colours = ["rgba(216, 180, 254, 0.4)", "rgba(16, 185, 129, 1)"];

    const area = d3
      .area<DataPoint>()
      .x((d: DataPoint) => xScale(d.date))
      .y0(height)
      .y1((d: DataPoint) => yScale_budget(d.value))
      .curve(d3.curveBasis);
    chartGroup
      .append("path")
      .datum(props.data.values)
      .attr("fill", line_colours[1])
      .style("opacity", 0.15)
      .attr("d", area);

    const line_budget = d3
      .line<DataPoint>()
      .x((d: DataPoint) => xScale(d.date))
      .y((d: DataPoint) => yScale_budget(d.value))
      .curve(d3.curveBasis);
    chartGroup
      .append("path")
      .datum(props.data.values)
      .attr("class", "line")
      .attr("fill", "none")
      .attr("stroke", line_colours[1])
      .attr("stroke-width", 1.5)
      .attr("d", line_budget);

    // chartGroup
    //   .selectAll("circle,budget")
    //   .data(props.data.values)
    //   .enter()
    //   .append("circle")
    //   .attr("r", 2)
    //   .attr("cx", (d) => xScale(d.date))
    //   .attr("cy", (d) => yScale_budget(d.value))
    //   .attr("fill", "red");

    // ------------------------------------------------------

    // Remove existing tooltips
    d3.selectAll(`.${props.uuid}`).remove();

    // Tooltip
    const tooltip = d3
      .select("body")
      .append("div")
      .attr("class", `tooltip ${props.uuid}`)
      .style("opacity", 0)
      .style("display", "none");

    // Vertical line for the x-axis
    const focusLine = svg
      .append("line")
      .attr("class", "focus-line")
      .style("opacity", 0)
      .attr("y1", 0)
      .attr("y2", height);

    // Mouse event handlers
    const bisect = d3.bisector((d: DataPoint) => d.date).left;

    svg
      .append("rect")
      .attr("width", width)
      .attr("height", height)
      .style("fill", "none")
      .style("pointer-events", "all")
      .on("mouseover", function () {
        tooltip.style("opacity", 1);
        tooltip.style("display", "block");
        focusLine.style("opacity", 0.15);
      })
      .on("mouseout", function () {
        tooltip.style("opacity", 0);
        tooltip.style("display", "none");
        focusLine.style("opacity", 0);
      })
      .on("mousemove", function (event) {
        const mouseX = d3.pointer(event)[0];
        const x0 = xScale.invert(mouseX);
        const i1 = bisect(props.data.values, x0, 1);

        const d01 = props.data.values[i1 - 1],
          d11 = props.data.values[i1] ?? d01;

        if (!d01) {
          return;
        }
        const d1 = +x0 - +d01.date > +d11.date - +x0 ? d11 : d01;

        tooltip.html(
          `<div class="flex flex-col">
            <div class="font-semibold">${dayjs(d1.date).format("Do MMM YYYY")}</div>
            <div class="font-mono flex items-center justify-between space-x-3">
              <span>${d1.value}</span>
            </div>
          </div>`,
        );
        // Position tooltip to the left or right of the cursor
        const tooltipWidth = 140; // Estimate width for positioning
        const pageWidth = document.body.clientWidth;

        let left = event.pageX + 10;
        if (event.pageX + tooltipWidth > pageWidth - margin.right) {
          left = event.pageX - tooltipWidth - 10;
        }

        tooltip
          .style("left", `${left}px`)
          .style("top", `${event.pageY - 28}px`);

        // Update the position of the vertical line
        focusLine.attr("x1", xScale(d1.date)).attr("x2", xScale(d1.date));
      });
  }
};

const start = computed(() => {
  return props.data.values[0].date;
});
const end = computed(() => {
  return props.data.values[props.data.values.length - 1].date;
});
onMounted(() => {
  window.addEventListener("resize", render);
  render();
});
onUpdated(() => {
  render();
});
onUnmounted(() => {
  window.removeEventListener("resize", render);
});
</script>
<template>
  <div ref="chartContainer" class="chart-container"></div>
</template>
<style scoped>
.chart-container {
  width: 100%;
  height: 100%;
}
svg {
  width: 100%;
  height: 100%;
}

.area {
  fill: #0773ff;
}
</style>
