import { defineStore } from "pinia";

import { nullsToUndefined } from "@/helpers/nulls-to-undefined.ts";
import { Campaign, CampaignRaw } from "@/models/campaign.ts";

export const useCampaignStore = defineStore("campaign", () => {
  const convertRawCampaign = (campaign_raw: CampaignRaw): Campaign => {
    return nullsToUndefined({
      ...campaign_raw,
    });
  };

  return {
    convertRawCampaign,
  };
});
