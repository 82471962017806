<script setup lang="ts">
import { HamburgerMenuIcon } from "@radix-icons/vue";
import {
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuRoot,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "radix-vue";
import { ref } from "vue";

import NumberBadge from "@/components/number-badge.vue";
import { useAccountStore } from "@/stores/account.ts";
import { useAuthStore } from "@/stores/auth.ts";
import { useWorkspaceStore } from "@/stores/workspace.ts";

const emit = defineEmits<{
  (e: "openAccountDialog"): void;
}>();

const isOpen = ref<boolean>(false);

const workspaceStore = useWorkspaceStore();
const accountStore = useAccountStore();
const authStore = useAuthStore();

const openAccountDialog = () => {
  emit("openAccountDialog");
};
</script>
<template>
  <DropdownMenuRoot
    v-if="authStore.user"
    :open="isOpen"
    @update:open="
      (payload: boolean) => {
        isOpen = payload;
      }
    "
    :modal="false"
  >
    <DropdownMenuTrigger
      class="group relative inline-flex aspect-square w-12 cursor-pointer items-center justify-center rounded-sm outline-none transition-colors"
      aria-label="Customise options"
    >
      <HamburgerMenuIcon
        class="h-auto w-6 text-zinc-600 transition-all duration-75 group-hover:text-white"
      ></HamburgerMenuIcon>
      <!--      <PersonIcon class="h-6 w-6 cursor-pointer text-zinc-700" />-->
      <number-badge
        v-if="workspaceStore.menuNotificationCount >= 1 && !isOpen"
        :n="workspaceStore.menuNotificationCount"
        class="absolute -top-0.5 left-8 cursor-pointer"
      ></number-badge>
    </DropdownMenuTrigger>

    <DropdownMenuPortal>
      <DropdownMenuContent
        class="data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade flex min-w-[272px] flex-col space-y-1 rounded-sm border border-zinc-800 bg-black px-1.5 py-2.5 shadow-[0_2px_30px_-15px_rgb(0,0,0,0.2)] outline outline-4 will-change-[opacity,transform]"
        :side-offset="10"
        align="end"
      >
        <template v-if="authStore.user && authStore.user.email">
          <div class="flex flex-col space-y-2 px-5 py-2.5">
            <span class="font-medium" v-if="authStore.user.name">{{
              authStore.user!.name
            }}</span>
            <span class="text-sm">{{ authStore.user?.email }}</span>
          </div>
          <div>
            <DropdownMenuSeparator
              class="mx-3 my-1.5 h-[1px] bg-zinc-100 dark:bg-zinc-800"
            />
          </div>
        </template>
        <!--        <DropdownMenuItem value="Profile" as-child>-->
        <!--          <router-link-->
        <!--            :to="{-->
        <!--              name: 'profile',-->
        <!--            }"-->
        <!--            class="group relative flex select-none items-center rounded-sm bg-white dark:bg-black px-5 py-2.5 leading-none outline-none hover:bg-zinc-100 data-[disabled]:pointer-events-none"-->
        <!--            >Profile</router-link-->
        <!--          >-->
        <!--        </DropdownMenuItem>-->
        <!--        <DropdownMenuItem-->
        <!--          value="Account"-->
        <!--          as-child-->
        <!--          v-if="workspaceStore.accountRoute"-->
        <!--        >-->
        <!--          <router-link-->
        <!--            :to="workspaceStore.accountRoute"-->
        <!--            class="group relative flex select-none items-center rounded-sm bg-white dark:bg-black px-5 py-2.5 leading-none outline-none transition-colors hover:bg-zinc-100 data-[disabled]:pointer-events-none"-->
        <!--            >Account</router-link-->
        <!--          >-->
        <!--        </DropdownMenuItem>-->
        <DropdownMenuItem
          value="Workspaces"
          as-child
          v-if="workspaceStore.showInvitations"
        >
          <router-link
            :to="{ name: 'invites' }"
            class="group relative flex select-none items-center space-x-2 rounded-sm bg-white px-5 py-2.5 leading-none outline-none transition-colors hover:bg-zinc-100 data-[disabled]:pointer-events-none dark:bg-black dark:hover:bg-zinc-900"
          >
            <span>Invitations</span>
            <number-badge :n="workspaceStore.invitationCount"></number-badge>
          </router-link>
        </DropdownMenuItem>
        <!--        <DropdownMenuItem-->
        <!--          value="Workspaces"-->
        <!--          as-child-->
        <!--          v-if="-->
        <!--            workspaceStore.acceptedCount! >= 2 &&-->
        <!--            !['workspaces', 'invites'].includes(-->
        <!--              route.matched[0].name! as string,-->
        <!--            )-->
        <!--          "-->
        <!--        >-->
        <!--          <router-link-->
        <!--            :to="{ name: 'workspaces' }"-->
        <!--            class="group relative flex select-none items-center rounded-sm bg-white px-5 py-2.5 leading-none outline-none transition-colors hover:bg-zinc-100 data-[disabled]:pointer-events-none dark:bg-black"-->
        <!--            >Workspaces</router-link-->
        <!--          >-->
        <!--        </DropdownMenuItem>-->
        <!--        <DropdownMenuItem value="Channels" as-child>-->
        <!--          <router-link-->
        <!--            :to="{-->
        <!--              name: 'channels',-->
        <!--              params: {-->
        <!--                workspace: route.params.workspace,-->
        <!--              },-->
        <!--            }"-->
        <!--            class="group relative flex cursor-pointer select-none items-center rounded-sm bg-white px-5 py-2.5 leading-none outline-none transition-colors hover:bg-zinc-100 data-[disabled]:pointer-events-none dark:bg-black dark:hover:bg-zinc-900"-->
        <!--            >Channels</router-link-->
        <!--          >-->
        <!--        </DropdownMenuItem>-->
        <!--        <DropdownMenuItem value="Storefronts" as-child>-->
        <!--          <router-link-->
        <!--            :to="{-->
        <!--              name: 'storefronts',-->
        <!--              params: {-->
        <!--                workspace: route.params.workspace,-->
        <!--              },-->
        <!--            }"-->
        <!--            class="group relative flex cursor-pointer select-none items-center rounded-sm bg-white px-5 py-2.5 leading-none outline-none transition-colors hover:bg-zinc-100 data-[disabled]:pointer-events-none dark:bg-black dark:hover:bg-zinc-900"-->
        <!--            >Storefronts</router-link-->
        <!--          >-->
        <!--        </DropdownMenuItem>-->
        <!--        <DropdownMenuItem value="OTP" as-child v-if="authStore.user?.admin">-->
        <!--          <router-link-->
        <!--            :to="{-->
        <!--              name: 'otp',-->
        <!--            }"-->
        <!--            class="group relative flex cursor-pointer select-none items-center rounded-sm bg-white px-5 py-2.5 leading-none outline-none transition-colors hover:bg-zinc-100 data-[disabled]:pointer-events-none dark:bg-black dark:hover:bg-zinc-900"-->
        <!--            >OTPs</router-link-->
        <!--          >-->
        <!--        </DropdownMenuItem>-->
        <template
          v-if="accountStore.accounts && accountStore.accounts.length >= 1"
        >
          <DropdownMenuItem value="Storefronts" as-child>
            <span
              class="group relative flex cursor-pointer select-none items-center rounded-sm bg-white px-5 py-2.5 leading-none outline-none transition-colors hover:bg-zinc-100 data-[disabled]:pointer-events-none dark:bg-black dark:hover:bg-zinc-900"
              @click="openAccountDialog"
              >Account</span
            >
          </DropdownMenuItem>
          <div>
            <DropdownMenuSeparator
              class="mx-3 my-1.5 h-[1px] bg-zinc-100 dark:bg-zinc-800"
            />
          </div>
        </template>
        <DropdownMenuItem
          value="Sign out"
          class="group relative flex cursor-pointer select-none items-center rounded-sm bg-white px-5 py-2.5 leading-none outline-none transition-colors hover:bg-zinc-100 data-[disabled]:pointer-events-none dark:bg-black dark:hover:bg-zinc-900"
          @click="authStore.signOut"
        >
          Sign out
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenuPortal>
  </DropdownMenuRoot>
</template>
