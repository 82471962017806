<script setup lang="ts">
import { ref } from "vue";
import { useRoute } from "vue-router";

import AdChannelIcon from "@/components/ad-channel-icon.vue";
import StyledButton from "@/components/form/styled-button.vue";
import Spinner from "@/components/spinner.vue";
import { AdChannelType } from "@/enums/ad-channel-type.ts";
import { ButtonType } from "@/enums/button-type.ts";
import { useWorkspaceStore } from "@/stores/workspace.ts";

const workspaceStore = useWorkspaceStore();
const route = useRoute();

const connectingMeta = ref<boolean>(false);
const connectingGoogle = ref<boolean>(false);

const connectGoogle = () => {
  connectingGoogle.value = true;
  window.location.href =
    "https://accounts.google.com/o/oauth2/v2/auth?" +
    `client_id=${import.meta.env.VITE_GOOGLE_CLIENT_ID}` +
    `&redirect_uri=${window.location.origin}/oauth/google-ad-channel` +
    `&response_type=code` +
    `&access_type=offline` +
    `&include_granted_scopes=true` +
    `&enable_granular_consent=false` +
    `&prompt=select_account consent` +
    `&scope=https://www.googleapis.com/auth/adwords` +
    `&state=${route.query.setup ? `setup` : `connect`}|${workspaceStore.workspace!.id}`;
};
const connectMeta = () => {
  connectingMeta.value = true;
  window.location.href =
    "https://www.facebook.com/v21.0/dialog/oauth?" +
    `client_id=${import.meta.env.VITE_META_APP_ID}` +
    `&redirect_uri=${window.location.origin}/oauth/meta-ad-channel` +
    `&state=${route.query.setup ? `setup` : `connect`}|${workspaceStore.workspace!.id}` +
    `&auth_type=rerequest` +
    `&config_id=${import.meta.env.VITE_META_AD_CHANNEL_CONFIG_ID}`;
};
</script>

<template>
  <div class="flex flex-col space-y-[14px] pt-[6px]">
    <styled-button
      :type="ButtonType.REGULAR"
      @click="connectGoogle"
      class="flex h-[80px] items-center justify-start space-x-[30px] p-[20px] pr-[30px]"
    >
      <div>
        <div class="relative flex items-center justify-center">
          <spinner v-if="connectingGoogle" class="absolute h-10 w-10"></spinner>
          <ad-channel-icon
            :type="AdChannelType.GOOGLE"
            class="h-10 w-10 cursor-pointer transition-all"
          />
        </div>
      </div>
      <span class="font-mono text-lg">Connect to Google Ads</span>
    </styled-button>
    <styled-button
      :type="ButtonType.REGULAR"
      @click="connectMeta"
      class="flex h-[80px] items-center justify-start space-x-[30px] p-[20px] pr-[30px]"
    >
      <div>
        <div class="relative flex items-center justify-center">
          <spinner v-if="connectingMeta" class="absolute h-10 w-10"></spinner>
          <ad-channel-icon
            :type="AdChannelType.META"
            class="h-10 w-10 cursor-pointer transition-all"
          />
        </div>
      </div>
      <span class="font-mono text-lg">Connect to Meta Ads</span>
    </styled-button>
  </div>
</template>
