<script setup lang="ts"></script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="120"
    height="76"
    x="0px"
    y="0px"
    viewBox="0 0 120 76"
  >
    <g fill="none" fill-rule="evenodd">
      <path
        fill="#F4F4F4"
        d="M111.999 0H8C3.582 0 0 3.59 0 8.008v59.984C0 72.415 3.591 76 8.001 76H112c4.419 0 8.001-3.59 8.001-8.008V8.008C120 3.585 116.409 0 111.999 0Z"
      />
      <path
        fill="#E87E04"
        d="M76.792 61.554c-4.557 3.347-11.162 5.132-16.85 5.132-7.974 0-15.153-2.938-20.584-7.826-.427-.384-.044-.908.468-.609 5.861 3.398 13.108 5.442 20.594 5.442 5.05 0 10.603-1.04 15.71-3.2.771-.327 1.417.503.662 1.06"
      />
      <path
        fill="#E87E04"
        d="M78.687 59.394c-.58-.741-3.85-.35-5.319-.177-.447.055-.515-.333-.112-.612 2.604-1.826 6.878-1.3 7.377-.687.498.616-.13 4.884-2.578 6.921-.375.313-.734.147-.566-.268.55-1.368 1.782-4.432 1.198-5.177"
      />
      <path
        fill="#221F1F"
        d="M64.55 34.491c0 3.216.082 5.897-1.55 8.753-1.316 2.322-3.402 3.75-5.732 3.75-3.18 0-5.033-2.415-5.033-5.979 0-7.034 6.326-8.311 12.315-8.311v1.787m8.354 20.118c-.548.487-1.34.522-1.957.197-2.75-2.275-3.24-3.332-4.754-5.502-4.544 4.62-7.76 6.001-13.655 6.001-6.968 0-12.397-4.283-12.397-12.862 0-6.698 3.647-11.26 8.831-13.489 4.498-1.973 10.778-2.322 15.578-2.867v-1.068c0-1.962.152-4.284-1.002-5.978-1.014-1.521-2.948-2.148-4.649-2.148-3.157 0-5.977 1.614-6.664 4.957-.14.743-.688 1.474-1.433 1.509l-8.04-.86c-.675-.15-1.421-.696-1.235-1.729 1.853-9.704 10.65-12.63 18.526-12.63 4.031 0 9.297 1.068 12.478 4.11 4.032 3.75 3.647 8.752 3.647 14.197v12.862c0 3.865 1.608 5.56 3.123 7.65.536.743.652 1.637-.024 2.194-1.69 1.404-4.695 4.016-6.35 5.479l-.023-.023"
      />
    </g>
  </svg>
</template>
