<script setup lang="ts">
import { PropType } from "vue";

import { Campaign } from "@/models/campaign.ts";
import { useCurrencyStore } from "@/stores/currency.ts";
import { useWorkspaceStore } from "@/stores/workspace.ts";

const currencyStore = useCurrencyStore();
const workspaceStore = useWorkspaceStore();

const props = defineProps({
  campaign: {
    type: Object as PropType<Campaign>,
    required: true,
  },
});
</script>
<template>
  <div
    class="flex flex-col items-start font-sans"
    v-if="
      props.campaign.lifetime_budget &&
      props.campaign.budget_remaining &&
      workspaceStore.workspace
    "
  >
    <span class="text-xs text-purple-300/70">Remaining budget</span>
    <span class="font-mono text-sm text-purple-300"
      >{{
        currencyStore.format(
          props.campaign.budget_remaining,
          workspaceStore.workspace.currency.id,
          props.campaign.currency_id,
        )
      }}
      /
      {{
        currencyStore.format(
          props.campaign.lifetime_budget,
          workspaceStore.workspace.currency.id,
          props.campaign.currency_id,
        )
      }}</span
    >
  </div>
  <div class="flex flex-col items-start" v-else-if="workspaceStore.workspace">
    <span class="font-sans text-xs text-purple-300/70">Monthly spend</span>
    <span class="font-mono text-sm text-purple-300">{{
      currencyStore.format(
        props.campaign.effective_daily_budget * (365.25 / 12),
        workspaceStore.workspace.currency.id,
        props.campaign.currency_id,
      )
    }}</span>
  </div>
</template>
