<script setup lang="ts">
import dayjs from "dayjs";
import { computed } from "vue";
import { useRoute } from "vue-router";

import ConnectAdChannelInner from "@/components/workspace/ad-channels/connect-ad-channel-options.vue";
import CampaignGroup from "@/components/workspace/campaigns/campaign-groups/campaign-group.vue";
import TimeframeSelect from "@/components/workspace/campaigns/timeframe-select.vue";
import Tabs from "@/components/workspace/tabs.vue";
import { useAccountStore } from "@/stores/account.ts";
import { useWorkspaceStore } from "@/stores/workspace.ts";

const accountStore = useAccountStore();
const workspaceStore = useWorkspaceStore();
const route = useRoute();
// const router = useRouter();

// const loaded = () => {
// if (!workspaceStore.workspace?.ad_channels.length) {
// router.replace({
//   name: "ad-channels",
//   params: {
//     params: route.params.workspace,
//   },
//   query: {
//     setup: "true",
//   },
// });
// return;
// } else if (!workspaceStore.workspace?.storefronts.length) {
//   router.replace({
//     name: "storefronts",
//     params: {
//       params: route.params.workspace,
//     },
//     query: {
//       setup: "true",
//     },
//   });
//   return;
// }
// };
//
// if (workspaceStore.workspace) {
//   loaded();
// } else {
//   watchOnce(() => workspaceStore.workspace, loaded);
// }

const locked = computed<boolean | undefined>(() => {
  if (accountStore.account === undefined) {
    return undefined;
  }
  if (accountStore.account.payment_overdue) {
    return true;
  }

  return (
    accountStore.account.payment_method === undefined &&
    accountStore.account.price_per_ad_channel > 0 &&
    (accountStore.account.free_trial_ends_at === undefined ||
      accountStore.account.free_trial_ends_at < dayjs.utc().unix() * 1000)
  );
});

const has_ad_channels = computed<boolean | undefined>(() => {
  if (workspaceStore.workspace === undefined) {
    return undefined;
  }
  return workspaceStore.workspace.ad_channels.length >= 1;
});
</script>
<template>
  <router-view v-if="route.name !== 'campaigns'"></router-view>
  <template v-else-if="locked !== undefined && has_ad_channels !== undefined">
    <div
      class="flex grow flex-col items-center justify-center"
      v-if="!has_ad_channels"
    >
      <connect-ad-channel-inner class="mt-[-100px]"></connect-ad-channel-inner>
    </div>
    <div v-else>
      <tabs class="mb-7 mt-0">
        <div
          class="relative top-1 flex select-none items-center space-x-12 text-base text-zinc-500"
        >
          <timeframe-select
            v-if="accountStore.account && !accountStore.account.payment_overdue"
          ></timeframe-select>
        </div>
      </tabs>
      <div class="px-9 opacity-50" v-if="locked">
        (Hidden whilst your account is overdue)
      </div>
      <template v-else>
        <div
          class="flex flex-col space-y-2"
          v-if="
            workspaceStore.groupedCampaigns &&
            workspaceStore.groupedCampaigns.length >= 1
          "
        >
          <campaign-group
            v-for="group_of_campaigns in workspaceStore.groupedCampaigns"
            :key="group_of_campaigns.key"
            :group-of-campaigns="group_of_campaigns"
          ></campaign-group>
        </div>
      </template>
    </div>
    <div class="h-16"></div>
  </template>
</template>
