<script setup lang="ts"></script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="120"
    height="76"
    x="0px"
    y="0px"
    viewBox="0 0 120 76"
  >
    <g fill="none" fill-rule="evenodd">
      <path
        fill="#227FBB"
        d="M0 8.008C0 3.589 3.582 0 8.001 0H112C116.409 0 120 3.585 120 8.008V15H0V8.008Z"
      ></path>
      <path
        fill="#E87E04"
        d="M0 67.992C0 72.415 3.591 76 8.001 76H112c4.419 0 8.001-3.59 8.001-8.008V61H0v6.992Z"
      ></path>
      <path fill="#F4F4F4" d="M0 15h120v46H0z"></path>
      <path
        fill="#227FBB"
        d="M83.735 22.337C82.052 21.692 79.416 21 76.125 21c-8.391 0-14.301 4.316-14.352 10.502-.047 4.573 4.22 7.124 7.44 8.646 3.306 1.56 4.417 2.555 4.401 3.947-.02 2.134-2.64 3.108-5.08 3.108-3.398 0-5.204-.482-7.993-1.67l-1.094-.506-1.192 7.124c1.983.888 5.651 1.658 9.459 1.698 8.926 0 14.72-4.267 14.786-10.873.032-3.62-2.23-6.375-7.129-8.646-2.968-1.472-4.786-2.455-4.766-3.945 0-1.323 1.538-2.738 4.862-2.738 2.777-.044 4.788.575 6.356 1.22l.76.367 1.152-6.897m21.774-.754h-6.562c-2.032 0-3.553.567-4.446 2.64l-12.61 29.163h8.916s1.458-3.92 1.787-4.782c.975 0 9.637.014 10.875.014.254 1.114 1.033 4.768 1.033 4.768h7.88l-6.873-31.803zm-10.47 20.512c.701-1.833 3.382-8.896 3.382-8.896-.05.085.697-1.842 1.126-3.037l.574 2.744 1.966 9.189h-7.049zM44.45 53.376l5.309-31.823h8.491l-5.312 31.823zM37.328 21.576l-8.313 21.701-.886-4.41c-1.547-5.083-6.37-10.592-11.76-13.35l7.602 27.832 8.984-.01 13.368-31.763h-8.995"
      ></path>
      <path
        fill="#E87E04"
        d="M21.303 21.557H7.61l-.109.662c10.653 2.634 17.701 9 20.627 16.648l-2.977-14.624c-.514-2.015-2.005-2.616-3.85-2.686"
      ></path>
    </g>
  </svg>
</template>
