<script setup lang="ts">
import { PropType, ref } from "vue";

import DialogBody from "@/components/dialog-body.vue";
import DialogTitlebar from "@/components/dialog-titlebar.vue";
import StyledButton from "@/components/form/styled-button.vue";
import FormGroupOld from "@/components/form-group-old.vue";
import { targetMetricName } from "@/enums/target-metric.ts";
import { api } from "@/helpers/api.ts";
import { Campaign } from "@/models/campaign.ts";
import { CampaignGroup } from "@/models/campaign-group.ts";
import { WorkspaceRaw } from "@/models/workspace.ts";
import { useCurrencyStore } from "@/stores/currency.ts";
import { useWorkspaceStore } from "@/stores/workspace.ts";

const emit = defineEmits<{
  (e: "close"): void;
}>();
const props = defineProps({
  campaign: {
    type: Object as PropType<Campaign>,
    required: true,
  },
});
const workspaceStore = useWorkspaceStore();
const currencyStore = useCurrencyStore();

const open = defineModel<boolean>();
const submitting = ref<boolean>(false);

const campaign_group_id = ref<number | undefined>(
  props.campaign.campaign_group_id,
);

type Errors = {
  campaign_group_id?: CampaignGroup;
};

const errors = ref<Errors>({});

const validate = () => {
  validateCampaignGroupId();
};

const validateCampaignGroupId = () => {
  errors.value.campaign_group_id = undefined;
};

const submit = () => {
  if (submitting.value) {
    return;
  }
  submitting.value = true;

  validate();

  if (errors.value.campaign_group_id) {
    submitting.value = false;
    return;
  }
  workspaceStore.workspace!.campaigns.forEach((campaign_: Campaign) => {
    if (campaign_.id === props.campaign.id) {
      campaign_.campaign_group_id = campaign_group_id.value;
    }
  });
  api
    .put("campaign-group/move-campaign", {
      campaign_id: props.campaign.id,
      campaign_group_id: campaign_group_id.value,
    })
    .then((workspace_raw: unknown) => {
      workspaceStore.mergeWorkspaceRaw(workspace_raw as WorkspaceRaw);
      submitting.value = false;
      open.value = false;
    });
};
</script>

<template>
  <div class="dialog-box">
    <dialog-titlebar
      @close="emit('close')"
      title="Assign campaign to campaign group"
    ></dialog-titlebar>
    <dialog-body>
      <div class="flex w-full min-w-[500px] flex-col space-y-5">
        <form-group-old>
          <template #label>
            <label
              for="name"
              :class="`form-label ${errors.campaign_group_id ? `invalid` : ``}`"
              >Campaign group</label
            ></template
          >
          <template #control>
            <select
              v-if="workspaceStore.workspace"
              class="input"
              v-model="campaign_group_id"
              @change="
                () => {
                  if (errors.campaign_group_id) {
                    validateCampaignGroupId();
                  }
                }
              "
            >
              <option
                v-for="campaign_group in workspaceStore.workspace
                  ?.campaign_groups ?? []"
                :key="campaign_group.id"
                :value="campaign_group.id"
              >
                {{ campaign_group.name }}{{ !!campaign_group.name ? ` ` : ``
                }}{{
                  `(${targetMetricName(campaign_group.target_metric)}, ${currencyStore.format(
                    campaign_group.amount,
                    workspaceStore.workspace.currency.id,
                    campaign_group.currency_id,
                  )})`
                }}
              </option>
            </select>
          </template>
          <template #error>
            <span class="form-error" v-if="errors.campaign_group_id">{{
              errors.campaign_group_id
            }}</span>
          </template>
        </form-group-old>
        <div class="flex w-full flex-col items-start space-y-6 pt-4">
          <styled-button :submitting="submitting" @click="submit">
            Submit
          </styled-button>
        </div>
      </div>
    </dialog-body>
  </div>
</template>
