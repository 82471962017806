<script setup lang="ts">
import { watchOnce } from "@vueuse/core";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import DialogWrapper from "@/components/dialog-wrapper.vue";
import StyledButton from "@/components/form/styled-button.vue";
import LoadingBar from "@/components/loading-bar.vue";
import AppHeader from "@/components/workspace/app-header.vue";
import MobileMenu from "@/components/workspace/mobile-menu.vue";
import Switcher from "@/components/workspace/switcher.vue";
import UserMenu from "@/components/workspace/user-menu.vue";
import AccountDialog from "@/dialogs/account.vue";
import CreateBudgetDialog from "@/dialogs/campaign-groups/settings.vue";
import { AccountTab } from "@/enums/account-tab.ts";
import { ButtonType } from "@/enums/button-type.ts";
import { api } from "@/helpers/api.ts";
import { Currency } from "@/models/currency.ts";
import { WorkspaceRaw } from "@/models/workspace.ts";
import { useAccountStore } from "@/stores/account.ts";
import { useAuthStore } from "@/stores/auth.ts";
import { useCurrencyStore } from "@/stores/currency.ts";
import { useWorkspaceStore } from "@/stores/workspace.ts";

dayjs.extend(utc);
dayjs.extend(timezone);

const workspaceStore = useWorkspaceStore();
const accountStore = useAccountStore();
// const visibility = useDocumentVisibility();

const authStore = useAuthStore();
const currencyStore = useCurrencyStore();
const router = useRouter();
const route = useRoute();

const simulation_active = ref<boolean>(false);

const initial_account_dialog_tab = ref<AccountTab | undefined>(undefined);

// const interval: number | undefined = undefined;

// const startLiving = () => {
//   if (interval !== undefined) {
//     clearInterval(interval);
//   }
//   interval = window.setInterval(() => {
//     if (workspaceStore.workspace) {
//       workspaceStore.reload(workspaceStore.workspace);
//     }
//   }, 60000); // 1 min
// };
// const stopLiving = () => {
//   clearInterval(interval);
//   interval = undefined;
// };
//
// onMounted(() => {
//   startLiving();
// });
// onBeforeUnmount(() => {
//   stopLiving();
// });

// watch(visibility, (current, previous) => {
//   if (current === "visible" && previous === "hidden") {
//     startLiving();
//     if (workspaceStore.workspace) {
//       workspaceStore.reload(workspaceStore.workspace);
//     }
//   }
//   if (current === "hidden" && previous === "visible") {
//     stopLiving();
//   }
// });

const loaded = () => {
  if (!authStore.user!.terms_agreed_at) {
    router.replace({
      name: "terms",
      query: {
        for: btoa(JSON.stringify(route.fullPath)),
      },
    });
    return;
  }
  // if (route.name === "workspace" && route.query.setup === "true") {
  //   create_budget_dialog_open.value = true;
  // }
};

const create_budget_dialog_open = ref<boolean>(false);
const account_dialog_open = ref<boolean>(false);

onMounted(() => {
  if (workspaceStore.workspaces !== undefined) {
    loaded();
  } else {
    watchOnce(() => workspaceStore.workspaces, loaded);
  }
});

const startSimulation = () => {
  simulation_active.value = true;
  runSimulation();
};
const stopSimulation = () => {
  simulation_active.value = false;
};

const runSimulation = () => {
  if (simulation_active.value && workspaceStore.workspace) {
    api
      .get(`workspace/simulate?workspace_id=${workspaceStore.workspace.id}`)
      .then((workspace_raw: unknown) => {
        workspaceStore.mergeWorkspaceRaw(workspace_raw as WorkspaceRaw);
        setTimeout(runSimulation, 1000);
      });
  }
};

watch(create_budget_dialog_open, (value) => {
  if (!value) {
    // remove setup=true from the route query
    if (route.query.setup) {
      router.replace({
        name: route.name as string,
        params: route.params,
        query: {
          ...route.query,
          setup: undefined,
        },
      });
    }
  }
});

const openBilling = () => {
  initial_account_dialog_tab.value = AccountTab.BILLING;
  account_dialog_open.value = true;
  nextTick().then(() => {
    initial_account_dialog_tab.value = undefined;
  });
};

const freeTrialEndingSoon = computed<boolean | undefined>(() => {
  if (!accountStore.account) {
    return undefined;
  }
  const timestampNow = dayjs.utc().unix() * 1000;

  return (
    accountStore.account.free_trial_ends_at !== undefined &&
    timestampNow >
      accountStore.account.free_trial_ends_at - 7 * 24 * 60 * 60 * 1000
  );
});

const amountOverdue = computed<number | undefined>(() => {
  if (!accountStore.account) {
    return undefined;
  }
  let overdue = 0.0;
  for (let i = 0; i < accountStore.account.monthly_usages.length; i++) {
    if (!accountStore.account.monthly_usages[i].paid) {
      overdue += accountStore.account.monthly_usages[i].total;
    }
  }
  return overdue;
});

const usd = computed<Currency | undefined>(() => {
  return currencyStore.findWithCode("USD");
});

const locked = computed<boolean | undefined>(() => {
  if (accountStore.account === undefined) {
    return undefined;
  }
  if (accountStore.account.payment_overdue) {
    return true;
  }

  return (
    accountStore.account.payment_method === undefined &&
    accountStore.account.price_per_ad_channel > 0 &&
    (accountStore.account.free_trial_ends_at === undefined ||
      accountStore.account.free_trial_ends_at < dayjs.utc().unix() * 1000)
  );
});

const simulationResetting = ref<boolean>(false);

const resetSimulation = () => {
  if (simulationResetting.value) {
    return;
  }
  const running = simulation_active.value;
  simulationResetting.value = true;
  stopSimulation();
  api
    .put(
      `workspace/reset-simulation?workspace_id=${workspaceStore.workspace!.id}`,
    )
    .then((workspaceRaw: unknown) => {
      workspaceStore.mergeWorkspaceRaw(workspaceRaw as WorkspaceRaw);
      if (running) {
        startSimulation();
      }
    })
    .finally(() => {
      simulationResetting.value = false;
    });
};

const has_ad_channels = computed<boolean | undefined>(() => {
  if (workspaceStore.workspace === undefined) {
    return undefined;
  }
  return workspaceStore.workspace.ad_channels.length >= 1;
});
</script>
<template>
  <div
    class="flex grow flex-col"
    v-if="
      accountStore.account !== undefined &&
      locked !== undefined &&
      amountOverdue !== undefined &&
      usd !== undefined
    "
  >
    <div
      @click="openBilling"
      v-if="locked"
      class="flex h-[40px] w-full cursor-pointer items-center justify-center border-2 border-rose-500/60 bg-rose-600/25 text-rose-500"
    >
      <span v-if="amountOverdue > 0">
        Your account is overdue by
        {{ currencyStore.format(amountOverdue, usd.id, usd.id) }}. Click here to
        resolve.</span
      >
      <span v-else>
        You need to add a payment method to continue using Charter. Click here
        to resolve.</span
      >
    </div>
    <div
      @click="openBilling"
      v-else-if="
        freeTrialEndingSoon === true &&
        accountStore.account?.payment_method === undefined
      "
      class="flex h-[40px] w-full cursor-pointer items-center justify-center bg-[#29EAB2] text-black"
    >
      <span class="">
        Your free trial will end soon. Please click here to enter a payment
        method.</span
      >
    </div>
    <div v-if="workspaceStore.workspace" class="flex grow flex-col">
      <app-header>
        <template #left
          ><switcher class="relative top-[3px] hidden lg:flex"></switcher
        ></template>
        <template #right
          ><div class="hidden items-center space-x-6 lg:flex">
            <div
              class="flex items-center space-x-8"
              v-if="
                workspaceStore.workspace &&
                (['campaigns'] as string[]).includes(route.name as string)
              "
            >
              <styled-button
                v-if="workspaceStore.workspace.simulated"
                @click="
                  () => {
                    resetSimulation();
                  }
                "
                :type="
                  simulation_active ? ButtonType.REGULAR : ButtonType.REGULAR
                "
                :submitting="simulationResetting"
              >
                {{
                  simulationResetting
                    ? `Resetting simulation...`
                    : `Reset simulation`
                }}
              </styled-button>
              <styled-button
                v-if="workspaceStore.workspace.simulated"
                @click="
                  () => {
                    if (simulation_active) {
                      stopSimulation();
                    } else {
                      startSimulation();
                    }
                  }
                "
                :type="
                  simulation_active ? ButtonType.REGULAR : ButtonType.REGULAR
                "
                :submitting="simulationResetting"
              >
                {{ simulation_active ? `Pause simulation` : `Run simulation` }}
              </styled-button>
              <styled-button
                v-if="
                  accountStore.account &&
                  !accountStore.account.payment_overdue &&
                  has_ad_channels
                "
                @click="create_budget_dialog_open = true"
                :type="
                  workspaceStore.workspace.campaign_groups.length === 0
                    ? ButtonType.PRIMARY
                    : ButtonType.REGULAR
                "
              >
                Create campaign group
              </styled-button>
            </div>
            <user-menu @open-account-dialog="account_dialog_open = true" />
          </div>
          <mobile-menu class="lg:hidden"></mobile-menu
        ></template>
      </app-header>
      <router-view></router-view>
    </div>
    <div class="justify-centerR flex grow items-center" v-else>
      <!--      <spinner class="h-9 w-9" />-->

      <loading-bar></loading-bar>
    </div>
    <dialog-wrapper v-model="create_budget_dialog_open">
      <create-budget-dialog
        v-if="workspaceStore.workspace"
        title="Create campaign group"
        @close="create_budget_dialog_open = false"
      ></create-budget-dialog>
    </dialog-wrapper>
    <dialog-wrapper v-model="account_dialog_open">
      <account-dialog
        v-if="workspaceStore.workspace"
        title="Account settings"
        @close="account_dialog_open = false"
        :initial-tab="initial_account_dialog_tab"
      ></account-dialog>
    </dialog-wrapper>
  </div>
</template>
