<script setup lang="ts">
import { CaretDownIcon } from "@radix-icons/vue";
import {
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuRoot,
  DropdownMenuTrigger,
} from "radix-vue";
import { PropType, ref } from "vue";

import DialogWrapper from "@/components/dialog-wrapper.vue";
import HistoryDialog from "@/dialogs/budget-change-history.vue";
import MoveCampaignIntoGroupDialog from "@/dialogs/campaign-groups/move-campaign.vue";
import RemoveCampaignFromGroupDialog from "@/dialogs/campaign-groups/remove-campaign.vue";
import { Campaign } from "@/models/campaign.ts";

const emit = defineEmits<{
  (e: "openMetrics"): void;
  (e: "openHistory"): void;
}>();
const props = defineProps({
  campaign: {
    type: Object as PropType<Campaign>,
    required: true,
  },
});

const isOpen = ref<boolean>(false);

const moveGroup = () => {
  if (props.campaign?.campaign_group_id === 1) {
    return;
  }
  move_dialog_open.value = true;
};

const removeGroup = () => {
  if (props.campaign?.campaign_group_id === 1) {
    return;
  }
  remove_dialog_open.value = true;
};

const openMetrics = () => {
  emit("openMetrics");
};

const history_open = ref<boolean>(false);
const move_dialog_open = ref<boolean>(false);
const remove_dialog_open = ref<boolean>(false);
</script>
<template>
  <dialog-wrapper v-model="history_open">
    <history-dialog
      :campaign="props.campaign"
      @close="history_open = false"
    ></history-dialog>
  </dialog-wrapper>
  <dialog-wrapper v-model="move_dialog_open">
    <move-campaign-into-group-dialog
      :campaign="props.campaign"
      @close="move_dialog_open = false"
    />
  </dialog-wrapper>
  <dialog-wrapper v-model="remove_dialog_open">
    <remove-campaign-from-group-dialog
      :campaign="props.campaign"
      @close="remove_dialog_open = false"
    />
  </dialog-wrapper>
  <DropdownMenuRoot
    :open="isOpen"
    @update:open="
      (payload: boolean) => {
        isOpen = payload;
      }
    "
    :modal="false"
  >
    <DropdownMenuTrigger
      class="hover:bg-zinc-100x group relative inline-flex aspect-square cursor-pointer items-center justify-center rounded-sm px-2 outline-none transition-colors"
      aria-label="Customise options"
    >
      <CaretDownIcon
        class="h-auto w-7 cursor-pointer text-zinc-400 transition-all duration-100 group-hover:text-black dark:text-zinc-600 dark:group-hover:text-white"
      />
    </DropdownMenuTrigger>

    <DropdownMenuPortal>
      <DropdownMenuContent
        class="data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade flex min-w-[272px] flex-col space-y-1 rounded-sm border border-zinc-800 bg-black px-1.5 py-2.5 shadow-[0_2px_30px_-15px_rgb(0,0,0,0.2)] outline outline-4 will-change-[opacity,transform]"
        :side-offset="10"
        align="end"
      >
        <DropdownMenuItem
          value="Assign to campaign group"
          class="group relative flex cursor-pointer select-none items-center rounded-sm bg-white px-5 py-2.5 leading-none outline-none transition-colors hover:bg-zinc-100 data-[disabled]:pointer-events-none dark:bg-black dark:hover:bg-zinc-900"
          @click="moveGroup"
        >
          {{
            props.campaign.campaign_group_id
              ? `Assign to campaign group`
              : `Assign to campaign group`
          }}
        </DropdownMenuItem>
        <DropdownMenuItem
          value="Remove from campaign group"
          class="group relative flex cursor-pointer select-none items-center rounded-sm bg-white px-5 py-2.5 leading-none outline-none transition-colors hover:bg-zinc-100 data-[disabled]:pointer-events-none dark:bg-black dark:hover:bg-zinc-900"
          @click="removeGroup"
          v-if="props.campaign.campaign_group_id"
        >
          Remove from campaign group
        </DropdownMenuItem>
        <!--        <DropdownMenuItem-->
        <!--          value="History"-->
        <!--          class="group relative flex cursor-pointer select-none items-center rounded-sm bg-white px-5 py-2.5 leading-none outline-none transition-colors hover:bg-zinc-100 data-[disabled]:pointer-events-none dark:bg-black dark:hover:bg-zinc-900"-->
        <!--          @click="history_open = true"-->
        <!--        >-->
        <!--          History-->
        <!--        </DropdownMenuItem>-->
        <DropdownMenuItem
          value="View metrics"
          class="group relative flex cursor-pointer select-none items-center rounded-sm bg-white px-5 py-2.5 leading-none outline-none transition-colors hover:bg-zinc-100 data-[disabled]:pointer-events-none dark:bg-black dark:hover:bg-zinc-900"
          @click="openMetrics"
        >
          View metrics
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenuPortal>
  </DropdownMenuRoot>
</template>
