<script setup lang="ts">
import { Capacitor } from "@capacitor/core";
import { useRoute } from "vue-router";

import bg from "@/assets/bg.png";
import FooterLinks from "@/components/landing/footer-links.vue";
import MobileHomeNavigation from "@/components/landing/mobile-home-navigation.vue";
import MobileIntro from "@/components/landing/mobile-intro.vue";
import Navigation from "@/components/landing/navigation.vue";
import Pricing from "@/components/landing/pricing.vue";
// import Pricing from "@/components/legal/pricing.vue";
import Splash from "@/components/landing/splash.vue";
import Steps from "@/components/landing/steps.vue";
import MobileTopLogo from "@/components/mobile-top-logo.vue";

const route = useRoute();

const VITE_MAINTENANCE = import.meta.env.VITE_MAINTENANCE === "true";
</script>
<template>
  <!--  linear-gradient(black, black),-->
  <div
    v-if="!VITE_MAINTENANCE"
    class="flex grow flex-col text-xl font-light leading-relaxed"
    :style="`background-image: url(${bg}); background-size: cover; background-position: top; background-attachment: fixed; background-blend-mode: saturation;`"
  >
    <div class="min-h-screen dark:bg-[rgb(0,0,0)]/92">
      <template v-if="!Capacitor.isNativePlatform()">
        <navigation></navigation>
        <template v-if="route.name === 'home'">
          <!--      <floating-icons></floating-icons>-->
          <splash></splash>
          <div class="mb-40 flex flex-col space-y-36">
            <div class="mt-12 flex flex-col space-y-24 px-7">
              <!--              <highlighted-feature></highlighted-feature>-->
              <steps></steps>
            </div>
            <!--            <div class="flex flex-col space-y-28 pt-44">-->
            <!--              <PlusIcon class="h-12 w-auto"></PlusIcon>-->
            <!--              <span class="pt-10 text-center text-5xl font-medium"-->
            <!--                >How it works</span-->
            <!--             >-->
            <!--              <features></features>-->
            <!--            </div>-->
            <!--            <integrations></integrations>-->
            <pricing></pricing>
            <!--            <faqs></faqs>-->
            <!--            <demo-cta></demo-cta>-->
          </div>
        </template>
        <template v-else>
          <div class="h-44"></div>
          <div class="container">
            <!--          <router-link class="mb-8 block text-lg" :to="{ name: 'home' }"-->
            <!--            >← Back to main page</router-link-->
            <!--          >-->
            <router-view></router-view>
          </div>
        </template>
        <footer-links></footer-links>
        <div class="h-[106px] sm:hidden"></div>
      </template>
      <template v-else>
        <mobile-top-logo></mobile-top-logo>
        <mobile-intro></mobile-intro>
        <mobile-home-navigation></mobile-home-navigation>
      </template>
    </div>
  </div>
  <div class="flex grow items-center justify-center" v-else>
    Update in progress... we'll be right back.
  </div>
</template>
