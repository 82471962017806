<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";

import existing_account_ad_channels from "@/assets/docs/existing-account-ad-channels.png";
import new_account_ad_channels from "@/assets/docs/new-account-ad-channels.png";
import { contents, Item, selectedItem } from "@/components/docs/contents.ts";

const route = useRoute();

const page = computed<Item | undefined>(() => selectedItem(route, contents));
</script>
<template>
  <div v-if="page" class="prose prose-invert py-4 pl-5 pr-7">
    <h1>{{ page.title }}</h1>
    <div class="rounded-md border border-white/30 pl-8 pr-8">
      <img class="object-cover" :src="new_account_ad_channels" />
    </div>
    <p>
      If you're connecting ad channels on a new account, you’ll be prompted to
      connect them immediately after sign up. If you want to connect another ad
      channel to an existing account, simply click “Ad Channels” then “Connect
      ad channel”
    </p>
    <div class="rounded-md border border-white/30 pl-8 pr-8">
      <img class="object-cover" :src="existing_account_ad_channels" />
    </div>
    <p>
      <span class="inline font-bold">Meta:</span> simply follow Meta’s
      instructions to connect your Meta account, then select the channel you
      want to connect from the drop down menu.
    </p>
    <p><span class="inline font-bold">Google:</span> coming soon!</p>
    <p>
      <span class="inline font-bold">Also in our roadmap:</span> TikTok,
      Snapchat, Reddit, LinkedIn
    </p>
  </div>
</template>
