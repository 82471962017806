<script setup lang="ts">
import {
  TooltipArrow,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipRoot,
  TooltipTrigger,
} from "radix-vue";
const props = defineProps({
  magic: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const open = defineModel<boolean>();
</script>

<template>
  <TooltipProvider>
    <TooltipRoot
      :disabled="props.disabled"
      v-model:open="open"
      :delay-duration="100"
    >
      <TooltipTrigger class="inline-flex">
        <slot name="trigger"></slot>
      </TooltipTrigger>
      <TooltipPortal class="z-[1000000]" :disabled="true">
        <TooltipContent
          :class="`data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade popover data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade select-none will-change-[transform,opacity] ${props.magic ? `magic` : ``} relative mx-5 rounded-sm border border-zinc-200 bg-white shadow-[0_3px_10px_0px_rgba(0,0,0,0.05)] dark:border-zinc-800 dark:bg-black`"
          :side-offset="5"
        >
          <div class="rounded-sm bg-black px-3.5 pb-2.5 pt-2">
            <slot name="content"></slot>
          </div>
          <TooltipArrow as-child>
            <div
              class="relative -top-0 h-[6px] w-[12px] overflow-hidden shadow-[0_0_20px_5px_rgba(255,255,255,0.8)] dark:shadow-none"
            >
              <div
                :class="`relative -top-[6px] h-[10px] w-[10px] rotate-45 border bg-white dark:bg-black ${props.magic ? 'border-[#bf5eff]' : 'border-zinc-200 dark:border-zinc-700'} shadow-[0_0_20px_5px_rgba(255,255,255,0.8)] dark:shadow-none`"
              ></div>
            </div>
          </TooltipArrow>
        </TooltipContent>
      </TooltipPortal>
    </TooltipRoot>
  </TooltipProvider>
</template>
