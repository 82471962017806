<script setup lang="ts">
import Amazon from "@/components/icons/payment/amazon.vue";
import AmericanExpress from "@/components/icons/payment/american-express.vue";
import Bitcoin from "@/components/icons/payment/bitcoin.vue";
import Delta from "@/components/icons/payment/delta.vue";
import DinersClub from "@/components/icons/payment/diners-club.vue";
import DirectDebit from "@/components/icons/payment/direct-debit.vue";
import Discover from "@/components/icons/payment/discover.vue";
import Jcb from "@/components/icons/payment/jcb.vue";
import Klarna from "@/components/icons/payment/klarna.vue";
import Maestro from "@/components/icons/payment/maestro.vue";
import Mastercard from "@/components/icons/payment/mastercard.vue";
import MoneyGram from "@/components/icons/payment/money-gram.vue";
import Paypal from "@/components/icons/payment/paypal.vue";
import Payu from "@/components/icons/payment/payu.vue";
import Rupay from "@/components/icons/payment/rupay.vue";
import Skrill from "@/components/icons/payment/skrill.vue";
import Solo from "@/components/icons/payment/solo.vue";
import Stripe from "@/components/icons/payment/stripe.vue";
import UnionPay from "@/components/icons/payment/union-pay.vue";
import Visa from "@/components/icons/payment/visa.vue";
import WesternUnion from "@/components/icons/payment/western-union.vue";
import WorldPay from "@/components/icons/payment/world-pay.vue";

const props = defineProps({
  brand: {
    type: String,
    required: true,
  },
});
</script>
<template>
  <amazon v-if="props.brand === 'amazon'" />
  <american-express v-else-if="props.brand === 'amex'" />
  <bitcoin v-else-if="props.brand === 'bitcoin'" />
  <delta v-else-if="props.brand === 'delta'" />
  <diners-club v-else-if="props.brand === 'diners'" />
  <direct-debit v-else-if="props.brand === 'direct_debit'" />
  <discover v-else-if="props.brand === 'discover'" />
  <jcb v-else-if="props.brand === 'jcb'" />
  <klarna v-else-if="props.brand === 'klarna'" />
  <maestro v-else-if="props.brand === 'maestro'" />
  <mastercard v-else-if="props.brand === 'mastercard'" />
  <money-gram v-else-if="props.brand === 'money_gram'" />
  <paypal v-else-if="props.brand === 'paypal'" />
  <payu v-else-if="props.brand === 'payu'" />
  <rupay v-else-if="props.brand === 'rupay'" />
  <skrill v-else-if="props.brand === 'skrill'" />
  <solo v-else-if="props.brand === 'solo'" />
  <stripe v-else-if="props.brand === 'stripe'" />
  <union-pay v-else-if="props.brand === 'unionpay'" />
  <visa v-else-if="props.brand === 'visa'" />
  <western-union v-else-if="props.brand === 'western_union'" />
  <world-pay v-else-if="props.brand === 'world_pay'" />
  <div v-else></div>
</template>
