<script setup lang="ts">
import dayjs from "dayjs";
import { PropType } from "vue";

import DialogBody from "@/components/dialog-body.vue";
import DialogTitlebar from "@/components/dialog-titlebar.vue";
import { Campaign } from "@/models/campaign.ts";
import { useCurrencyStore } from "@/stores/currency.ts";
import { useWorkspaceStore } from "@/stores/workspace.ts";

const emit = defineEmits<{
  (e: "close"): void;
}>();
const props = defineProps({
  campaign: {
    type: Object as PropType<Campaign>,
    required: true,
  },
});
const currencyStore = useCurrencyStore();
const workspaceStore = useWorkspaceStore();
</script>

<template>
  <div>
    <dialog-titlebar
      @close="emit('close')"
      title="Budget change history"
    ></dialog-titlebar>
    <dialog-body>
      <div class="flex w-full max-w-[800px] flex-col space-y-5 py-1">
        <div class="flex w-full flex-row items-start space-x-3 pb-1.5">
          <table class="table w-full" v-if="workspaceStore.workspace">
            <thead>
              <tr class="text-sm uppercase opacity-50">
                <td>Time</td>
                <td>Before</td>
                <td>After</td>
                <td>Change</td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="change in props.campaign.budget_changes"
                :key="change.id"
              >
                <td>
                  <span v-if="change.applied_at">{{
                    dayjs
                      .unix(change.applied_at / 1000)
                      .format("h:mma, Do MMM YYYY")
                  }}</span>
                </td>
                <td>
                  {{
                    currencyStore.format(
                      change.effective_daily_budget_before,
                      workspaceStore.workspace.currency.id,
                      change.currency_id,
                    )
                  }}
                </td>
                <td>
                  {{
                    currencyStore.format(
                      change.effective_daily_budget_after,
                      workspaceStore.workspace.currency.id,
                      change.currency_id,
                    )
                  }}
                </td>
                <td>
                  <span
                    v-if="
                      change.effective_daily_budget_after -
                        change.effective_daily_budget_before >
                      0
                    "
                    class="text-emerald-600"
                    >{{
                      currencyStore.format(
                        change.effective_daily_budget_after -
                          change.effective_daily_budget_before,
                        workspaceStore.workspace.currency.id,
                        change.currency_id,
                      )
                    }}</span
                  >
                  <span
                    v-if="
                      change.effective_daily_budget_after -
                        change.effective_daily_budget_before <
                      0
                    "
                    class="text-rose-700"
                    >{{
                      currencyStore.format(
                        change.effective_daily_budget_after -
                          change.effective_daily_budget_before,
                        workspaceStore.workspace.currency.id,
                        change.currency_id,
                      )
                    }}</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </dialog-body>
  </div>
</template>
