<script setup lang="ts">
import { useNow } from "@vueuse/core";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { computed, PropType, ref } from "vue";

import chip from "@/assets/chip.png";
import AddPaymentMethod from "@/components/account/add-payment-method.vue";
import BillingWorkspaceUsage from "@/components/account/billing-workspace-usage.vue";
import DownloadDropdown from "@/components/account/download-dropdown.vue";
import MonthSelect from "@/components/account/month-select.vue";
import AdChannelIcon from "@/components/ad-channel-icon.vue";
import StyledButton from "@/components/form/styled-button.vue";
import PaymentIcon from "@/components/icons/payment-icon.vue";
import { ButtonType } from "@/enums/button-type.ts";
import { api } from "@/helpers/api.ts";
import { formatNumber } from "@/helpers/formatting.ts";
import { Account, AccountRaw } from "@/models/account.ts";
import { Currency } from "@/models/currency.ts";
import { MonthlyUsage } from "@/models/monthly-usage.ts";
import { MonthlyUsageAdChannel } from "@/models/monthly-usage-ad-channel.ts";
import { useAccountStore } from "@/stores/account.ts";
import { useCurrencyStore } from "@/stores/currency.ts";
import { useWorkspaceStore } from "@/stores/workspace.ts";

dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const props = defineProps({
  account: {
    type: Object as PropType<Account>,
    required: true,
  },
});

const workspaceStore = useWorkspaceStore();
const currencyStore = useCurrencyStore();
const accountStore = useAccountStore();

const addCardOpen = ref<boolean>(false);

const usd = computed<Currency | undefined>(() => {
  return currencyStore.findWithCode("USD");
});

const isFree = computed<boolean>(() => {
  return props.account.price_per_ad_channel === 0;
});

const isInTrial = computed<boolean>(() => {
  if (isFree.value) {
    return false;
  }
  if (props.account.free_trial_ends_at === null) {
    return false;
  }
  const now = new Date().getTime();

  return (
    now < props.account.free_trial_ends_at! &&
    (props.account.free_trial_starts_at === null ||
      now >= props.account.free_trial_starts_at!)
  );
});

// const estimatedMonthlySubscription = computed<number | undefined>(() => {
//   if (workspaceStore.workspaces === undefined) {
//     return undefined;
//   }
//
//   let numberOfAdAccountInAccount = 0;
//
//   for (let i = 0; i < workspaceStore.workspaces!.length; i++) {
//     if (workspaceStore.workspaces![i].account.id === props.account.id) {
//       for (
//         let ii = 0;
//         ii < workspaceStore.workspaces![i].ad_channels.length;
//         ii++
//       ) {
//         numberOfAdAccountInAccount++;
//       }
//     }
//   }
//
//   return numberOfAdAccountInAccount * props.account.price_per_ad_channel;
// });

const trialEndsAt = computed<dayjs.Dayjs | undefined>(() => {
  if (!isInTrial.value) {
    return undefined;
  }
  if (props.account.free_trial_ends_at === undefined) {
    return undefined;
  }
  return dayjs.utc(props.account.free_trial_ends_at).tz(props.account.timezone);
});

const nextBillingMoment = computed<dayjs.Dayjs>(() => {
  return dayjs
    .utc()
    .startOf("month")
    .add(1, "month")
    .add(9, "hours")
    .tz(props.account.timezone);
});
const nextBillingMonth = computed<dayjs.Dayjs>(() => {
  return dayjs.utc().startOf("month");
});

const billedMonthsPlusCurrent = computed<dayjs.Dayjs[]>(() => {
  const months: dayjs.Dayjs[] = [];

  for (let i = 0; i < props.account.monthly_usages.length; i++) {
    months.push(
      dayjs.utc(
        `${props.account.monthly_usages[i].year}-${String(props.account.monthly_usages[i].month).padStart(2, "0")}-01`,
      ),
    );
  }
  months.push(nextBillingMonth.value);

  return months;
});

const currentMonthCostsSoFar = computed<MonthlyUsage | undefined>(() => {
  if (workspaceStore.workspaces === undefined) {
    return undefined;
  }

  const now = dayjs(useNow().value).unix() * 1000;

  const start_of_month = dayjs.utc().startOf("month").unix() * 1000;
  const end_of_month = dayjs.utc().endOf("month").unix() * 1000;
  const hours_in_month = (end_of_month + 1000 - start_of_month) / 3600000;

  let freeTrialHours = 0;
  let billableHours = 0;
  let totalHours = 0;

  const price_per_hour = props.account.price_per_ad_channel / hours_in_month;

  const adChannelUsages: MonthlyUsageAdChannel[] = [];
  for (let i = 0; i < workspaceStore.workspaces.length; i++) {
    if (workspaceStore.workspaces[i].account.id === props.account.id) {
      for (
        let ii = 0;
        ii < workspaceStore.workspaces[i].ad_channels.length;
        ii++
      ) {
        const total_start = Math.max(
          start_of_month,
          workspaceStore.workspaces[i].ad_channels[ii].created_at,
        );
        const total_end = Math.min(
          now,
          workspaceStore.workspaces[i].ad_channels[ii].deleted_at ?? now,
        );

        const includes_trial =
          props.account.free_trial_ends_at !== undefined &&
          total_start < props.account.free_trial_ends_at;

        const trial_start = total_start;
        const trial_ends = includes_trial
          ? Math.min(total_end, props.account.free_trial_ends_at ?? total_end)
          : trial_start;

        const billable_start = includes_trial ? trial_ends : total_start;
        const billable_end = total_end;

        const total_hours = (total_end - total_start) / 3600000;
        const free_trial_hours = (trial_ends - trial_start) / 3600000;
        const billable_hours = (billable_end - billable_start) / 3600000;

        const adChannelUsage = {
          id: 0,
          monthly_usage_id: 0,
          month: nextBillingMonth.value.month() + 1,
          year: nextBillingMonth.value.year(),
          price_per_ad_channel: props.account.price_per_ad_channel,
          account_id: props.account.id,
          workspace_id: workspaceStore.workspaces[i].id,
          ad_channel_id: workspaceStore.workspaces[i].ad_channels[ii].id,
          workspace_name: workspaceStore.workspaces[i].name,
          ad_channel_name: workspaceStore.workspaces[i].ad_channels[ii].name,
          ad_channel_type: workspaceStore.workspaces[i].ad_channels[ii].type,
          free_trial_hours: free_trial_hours,
          billable_hours: billable_hours,
          total_hours: total_hours,
          total: billable_hours * price_per_hour,
        };
        adChannelUsages.push(adChannelUsage);

        freeTrialHours += adChannelUsage.free_trial_hours;
        billableHours += adChannelUsage.billable_hours;
        totalHours += adChannelUsage.total_hours;
      }
    }
  }

  return {
    id: 0,
    account_id: props.account.id,
    month: nextBillingMonth.value.month() + 1,
    year: nextBillingMonth.value.year(),
    price_per_ad_channel: props.account.price_per_ad_channel,
    hours_per_month: hours_in_month,
    price_per_hour: price_per_hour,
    paid: false,
    paid_at: undefined,
    ad_channels: adChannelUsages,
    free_trial_hours: freeTrialHours,
    billable_hours: billableHours,
    total_hours: totalHours,
    total: billableHours * price_per_hour,
  };
});

const selectedMonth = ref<dayjs.Dayjs>(nextBillingMonth.value);

// const selectedMonthHasMonthlyUsageInDatabase = computed<boolean | undefined>(
//   () => {
//     if (selectedMonth.value === undefined) {
//       return undefined;
//     }
//     if (
//       nextBillingMonth.value.year() === selectedMonth.value.year() &&
//       nextBillingMonth.value.month() === selectedMonth.value.month()
//     ) {
//       return false;
//     }
//     return true;
//   },
// );

const selectedMonthPriorUsage = computed<MonthlyUsage | undefined>(() => {
  if (
    nextBillingMonth.value.year() === selectedMonth.value.year() &&
    nextBillingMonth.value.month() === selectedMonth.value.month()
  ) {
    return currentMonthCostsSoFar.value;
  }
  for (let i = 0; i < props.account.monthly_usages.length; i++) {
    if (
      props.account.monthly_usages[i].year === selectedMonth.value.year() &&
      props.account.monthly_usages[i].month - 1 === selectedMonth.value.month()
    ) {
      return props.account.monthly_usages[i];
    }
  }
  return undefined;
});

type UsageWorkspace = {
  workspace_id: number;
  workspace_name?: string;
  total: number;
  ad_channels: MonthlyUsageAdChannel[];
};

const workspacesWithUsage = computed<UsageWorkspace[]>(() => {
  if (selectedMonthPriorUsage.value === undefined) {
    return [];
  }
  const workspaces_: UsageWorkspace[] = [];
  for (let i = 0; i < selectedMonthPriorUsage.value.ad_channels.length; i++) {
    let index: number | null = null;
    for (let ii = 0; ii < workspaces_.length; ii++) {
      if (
        workspaces_[ii].workspace_id ===
        selectedMonthPriorUsage.value.ad_channels[i].workspace_id
      ) {
        index = ii;
        break;
      }
    }
    if (index === null) {
      workspaces_.push({
        workspace_id:
          selectedMonthPriorUsage.value.ad_channels[i].workspace_id!,
        workspace_name:
          selectedMonthPriorUsage.value.ad_channels[i].workspace_name,
        total: 0,
        ad_channels: [],
      });
      index = workspaces_.length - 1;
    }

    workspaces_[index].total +=
      selectedMonthPriorUsage.value.ad_channels[i].total;

    workspaces_[index].ad_channels.push(
      selectedMonthPriorUsage.value.ad_channels[i],
    );
  }
  return workspaces_;
});

const amountOverdue = computed<number | undefined>(() => {
  let overdue = 0.0;
  for (let i = 0; i < props.account.monthly_usages.length; i++) {
    if (props.account.monthly_usages[i].paid === false) {
      overdue += props.account.monthly_usages[i].total;
    }
  }
  return overdue;
});

const retrySubmitting = ref<boolean>(false);

const retryPayment = () => {
  if (retrySubmitting.value) {
    return;
  }
  retrySubmitting.value = true;

  api
    .put(`account/billing/retry`, {
      account_id: props.account.id,
    })
    .then((data: unknown) => {
      accountStore.mergeAccount(
        accountStore.convertRawAccount(data as AccountRaw),
      );
    })
    .finally(() => {
      retrySubmitting.value = false;
    });
};
</script>
<template>
  <div class="flex flex-col" v-if="!addCardOpen">
    <div
      v-if="props.account.payment_overdue"
      class="w-full rounded-[3px] border-2 border-rose-500/60 bg-rose-600/25 px-[30px] pb-[24px] pt-[20px] text-rose-500"
    >
      <span class="mb-[14px] block text-[18px] font-medium">{{
        props.account.payment_method
          ? `There was a problem with your payment`
          : `Please add a payment method`
      }}</span>
      <span class="block text-[16px] font-normal">{{
        props.account.payment_method
          ? `We were unable to take payment for your account. Please retry the payment or enter a new payment method to reactivate your account.`
          : `Please add a payment method to continue using Charter.`
      }}</span>
      <div class="item-end mt-[25px] flex justify-between">
        <div class="pb-[6px]">
          <template v-if="usd && amountOverdue">
            <div class="mb-[10px] text-[16px] font-medium">Amount overdue</div>
            <div class="text-[40px]">
              {{ currencyStore.format(amountOverdue, usd.id, usd.id) }}
            </div>
          </template>
        </div>
        <div class="flex flex-col justify-end">
          <styled-button
            @click="retryPayment"
            :submitting="retrySubmitting"
            :type="ButtonType.PRIMARY"
            v-if="props.account.payment_method"
            >Retry payment</styled-button
          >
        </div>
      </div>
    </div>
    <div class="flex border-0 border-b-[1px] border-white/10">
      <div class="grow pb-[24px] pl-[26px] pr-[30px] pt-[16px]">
        <template v-if="isInTrial">
          <span class="mb-[14px] block text-[18px] font-medium"
            >30-day free trial</span
          >
          <span
            class="block text-[16px] font-light text-white/50"
            v-if="trialEndsAt"
            >Your free trial ends at {{ trialEndsAt.format("H:mma") }} on the
            {{ trialEndsAt.format("Do of MMMM YYYY") }} ({{
              props.account.timezone
            }}).{{
              props.account.payment_method === undefined
                ? ` To continue using Charter after your free trial, please add a payment method to your account.`
                : ``
            }}</span
          >
          <span class="mb-[14px] mt-[20px] block text-[16px] font-light"
            >Price per ad channel per month</span
          >
          <span class="block text-[40px] font-normal" v-if="usd"
            >{{
              currencyStore.format(account.price_per_ad_channel, usd.id, usd.id)
            }}
            <span class="text-[24px]">USD</span></span
          >
        </template>
        <template v-else-if="isFree">
          <span class="mb-[14px] block text-[18px] font-medium"
            >Free account
          </span>
          <span class="block text-[16px] font-light text-white/50"
            >No payments will be taken and you do not need to enter a payment
            method. Enjoy using Charter!</span
          >
        </template>
        <template v-else>
          <span class="mb-[14px] block text-[18px] font-medium"
            >Next payment</span
          >
          <span class="block text-[16px] font-light text-white/50"
            >We will attempt to take payment for the month of
            <span class="font-medium">{{
              nextBillingMonth.format("MMMM")
            }}</span>
            at around {{ nextBillingMoment.format("H:mma") }} on the
            {{ nextBillingMoment.format("Do of MMMM YYYY") }} ({{
              props.account.timezone
            }}).</span
          >
          <span class="mb-[14px] mt-[20px] block text-[16px] font-light"
            >Price per ad channel per month</span
          >
          <span class="block text-[40px] font-normal" v-if="usd"
            >{{
              currencyStore.format(account.price_per_ad_channel, usd.id, usd.id)
            }}
            <span class="text-[24px]">USD</span></span
          >
        </template>
      </div>
      <div
        v-if="!isFree"
        class="w-[340px] shrink-0 border-l-[1px] border-white/10 pb-[36px] pl-[26px] pr-[30px] pt-[16px]"
      >
        <div class="flex items-baseline justify-between">
          <span class="mb-[14px] block text-[18px] font-medium"
            >Payment method</span
          >
          <span
            class="cursor-pointer text-[14px] font-light text-white/50 transition-all duration-75 hover:text-white"
            @click="addCardOpen = true"
            v-if="props.account.payment_method"
            >Change</span
          >
        </div>
        <template v-if="props.account.payment_method">
          <div
            class="relative mt-[2px] flex aspect-[1.586] flex-col rounded-sm bg-[#8277B5] pl-[22px] font-medium"
          >
            <div class="pb-[16px] pt-[31px]">
              <img :src="chip" alt="chip" class="h-[36px] w-[36px]" />
            </div>
            <span class="pb-[24px] text-[18px]"
              >**** **** ****
              {{ props.account.payment_method.stripe_card_last4 }}</span
            >
            <span class="text-[16px]">
              {{
                `${props.account.payment_method.stripe_card_exp_month.toString().padStart(2, "0")}/${props.account.payment_method.stripe_card_exp_year.toString().slice(-2)}`
              }}</span
            >
            <payment-icon
              :brand="props.account.payment_method.stripe_card_brand"
              class="absolute bottom-[23px] right-[22px] h-[26px] w-auto"
            ></payment-icon>
          </div>
        </template>
        <styled-button
          v-else
          :type="ButtonType.PRIMARY"
          @click="addCardOpen = true"
          class="h-[40px] w-full"
          >Add payment method</styled-button
        >
      </div>
    </div>
    <div
      class="flex flex-col space-y-[16px] pb-[36px] pl-[20px] pr-[24px] pt-[16px]"
    >
      <div class="flex items-center justify-between">
        <div class="flex items-center justify-start space-x-[20px] px-[6px]">
          <span class="text-[18px] font-medium">Usage</span>
          <div class="relative top-[1px]">
            <month-select
              :billed-months="billedMonthsPlusCurrent"
              v-model="selectedMonth"
            ></month-select>
          </div>
        </div>
        <div
          class="flex items-center justify-start space-x-[20px] px-[6px]"
          v-if="selectedMonthPriorUsage"
        >
          <div class="relative">
            <download-dropdown
              v-if="
                !!selectedMonthPriorUsage.invoice_id ||
                !!selectedMonthPriorUsage.receipt_id
              "
              :monthly-usage="selectedMonthPriorUsage"
            ></download-dropdown>
          </div>
          <span class="font-mono text-[14px] font-medium">
            <span
              class="text-white/50"
              v-if="selectedMonthPriorUsage.id === 0"
            ></span>
            <span
              class="text-emerald-500"
              v-else-if="selectedMonthPriorUsage.paid"
              >Paid</span
            >
            <span class="text-rose-500" v-else>UNPAID</span>
          </span>
        </div>
      </div>
      <div
        class="flex flex-col space-y-[7px]"
        v-if="selectedMonthPriorUsage !== undefined"
      >
        <billing-workspace-usage
          v-for="workspaceUsage in workspacesWithUsage"
          :key="workspaceUsage.workspace_id"
        >
          <template #summary>
            <div class="flex items-center justify-between">
              <span>{{
                workspaceUsage.workspace_name ?? "Primary workspace"
              }}</span>
              <span v-if="usd" class="font-mono font-normal">{{
                currencyStore.format(workspaceUsage.total, usd.id, usd.id)
              }}</span>
            </div>
          </template>
          <template #details>
            <div
              class="mt-[12px] flex items-center justify-between"
              v-for="usageAdChannel in workspaceUsage.ad_channels"
              :key="usageAdChannel.ad_channel_id"
            >
              <div class="flex items-center justify-start space-x-[12px]">
                <div class="text-white/60">
                  <svg
                    width="11"
                    height="6"
                    viewBox="0 0 11 6"
                    class="fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.5 5C10.7761 5 11 5.22386 11 5.5C11 5.77614 10.7761 6 10.5 6L1 6L1 5L10.5 5Z"
                      fill="currentColor"
                    />
                    <path
                      d="M1 6C0.447715 6 -4.37697e-07 5.55228 -3.89414e-07 5L3.98811e-09 0.5C2.81293e-08 0.223857 0.223858 -6.78525e-08 0.5 -4.37114e-08C0.776143 -1.95703e-08 1 0.223858 1 0.5L1 6Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
                <ad-channel-icon
                  :type="usageAdChannel.ad_channel_type"
                  class="h-[16px] w-[16px]"
                ></ad-channel-icon>
                <span class="font-light">{{
                  usageAdChannel.ad_channel_name ??
                  usageAdChannel.ad_channel_type.replace(/\b\w/g, (match) =>
                    match.toUpperCase(),
                  )
                }}</span>
              </div>
              <div class="flex items-center space-x-[30px]">
                <span class="text-[14px] font-light"
                  >{{ formatNumber(usageAdChannel.total_hours / 24, 2) }} days{{
                    usageAdChannel.free_trial_hours > 0
                      ? ` (${usageAdChannel.total_hours !== usageAdChannel.free_trial_hours ? `${formatNumber(usageAdChannel.free_trial_hours / 24, 2)} in ` : ""}free trial)`
                      : ""
                  }}</span
                >
                <span v-if="usd" class="font-mono text-[14px] font-light">{{
                  currencyStore.format(usageAdChannel.total, usd.id, usd.id)
                }}</span>
              </div>
            </div>
          </template>
        </billing-workspace-usage>
      </div>
    </div>
  </div>
  <add-payment-method
    :account="props.account"
    v-else
    @cancel="addCardOpen = false"
    @complete="addCardOpen = false"
  ></add-payment-method>
</template>
