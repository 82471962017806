import { defineStore } from "pinia";

import { nullsToUndefined } from "@/helpers/nulls-to-undefined.ts";
import { CampaignGroup, CampaignGroupRaw } from "@/models/campaign-group.ts";
import { useAccountStore } from "@/stores/account.ts";

export const useCampaignGroupStore = defineStore("campaign-group", () => {
  const accountStore = useAccountStore();

  const convertRawCampaignGroup = (
    campaign_group_raw: CampaignGroupRaw,
  ): CampaignGroup => {
    return nullsToUndefined({
      ...campaign_group_raw,
      account: accountStore.find(campaign_group_raw.account_id)!,
      // workspace: workspace_raw.workspace.map((campaign: CampaignRaw) =>
      //   campaignStore.convertRawCampaign(campaign),
      // ),
    }) as CampaignGroup;
  };
  return {
    convertRawCampaignGroup,
  };
});
