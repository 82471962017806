<script setup lang="ts">
const props = defineProps({
  speed: {
    type: Number,
    required: false,
    default: 1,
  },
  height: {
    type: Number,
    required: false,
    default: 3,
  },
  barWidth: {
    type: Number,
    required: false,
    default: 50,
  },
});
</script>
<template>
  <div
    :style="`height: ${props.height}px`"
    class="w-full max-w-16 overflow-hidden rounded-sm"
  >
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 100 30"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <g opacity="0">
        <animate
          attributeName="opacity"
          from="0"
          to="1"
          dur="0.7s"
          fill="freeze"
        />
        <rect width="100" height="30" fill="rgba(255,255,255,0.1)"></rect>
        <rect :width="props.barWidth" height="30" class="fill-current">
          <animate
            attributeName="x"
            :from="`-${props.barWidth}`"
            to="100"
            :dur="`${props.speed}s`"
            repeatCount="indefinite"
            keySplines="0.42 0 0.58 1"
            calcMode="spline"
          />
        </rect>
      </g>
    </svg>
  </div>
</template>
