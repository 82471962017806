<script setup lang="ts">
import { useWindowScroll } from "@vueuse/core";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import bg from "@/assets/bg.png";
import DialogWrapper from "@/components/dialog-wrapper.vue";
import CharterIcon from "@/components/icons/charter-icon.vue";
import CountryPickerDialog from "@/components/landing/country-picker-dialog.vue";
import { useAuthStore } from "@/stores/auth.ts";
import { useInternationalisationStore } from "@/stores/internationalisation.ts";

const authStore = useAuthStore();
const internationalisationStore = useInternationalisationStore();

const { t } = useI18n();

const { y: scroll_y } = useWindowScroll();

const scrolled = computed(() => {
  return scroll_y.value > 50;
});

const country_select_open = ref<boolean>();
</script>
<template>
  <!--  linear-gradient(black, black),-->

  <nav
    :style="`background-image: url(${bg}); background-size: cover; background-position: top; background-attachment: fixed; background-blend-mode: saturation;`"
    :class="`fixed z-50 flex h-20 w-full font-mono text-lg font-normal transition-all duration-200 ${scrolled ? `shadow-subtle lg:h-20 lg:shadow-subtler` : `md:shadow-none lg:h-28`} block`"
  >
    <div
      class="flex grow items-center justify-center px-8 lg:px-12 dark:bg-[rgb(0,0,0)]/92"
    >
      <div class="flex w-full max-w-[1700px] items-center justify-between">
        <div
          class="hidden flex-grow basis-1 items-center justify-start 2xl:flex"
        >
          <div class="flex items-center space-x-10">
            <router-link
              :to="{ name: 'home', hash: '#how-it-works' }"
              class="select-none"
              >{{ t("landing.navigation.how-it-works") }}</router-link
            >
            <!--            <router-link-->
            <!--              :to="{ name: 'home', hash: '#pricing' }"-->
            <!--              class="select-none"-->
            <!--              >{{ t("landing.navigation.pricing") }}</router-link-->
            <!--            >-->
            <!--            <router-link-->
            <!--              :to="{ name: 'home', hash: '#integrations' }"-->
            <!--              class="select-none"-->
            <!--              >{{ t("landing.navigation.integrations") }}</router-link-->
            <!--            >-->
            <router-link
              :to="{ name: 'home', hash: '#pricing' }"
              class="select-none"
              >{{ t("landing.navigation.pricing") }}</router-link
            >

            <!--            <router-link-->
            <!--              :to="{ name: 'home', hash: '#faq' }"-->
            <!--              class="select-none"-->
            <!--              >{{ t("landing.navigation.faqs") }}</router-link-->
            <!--            >-->
          </div>
        </div>
        <div class="flex-shrink">
          <router-link
            :to="{ name: 'home' }"
            class="flex basis-0 items-center space-x-2.5 font-sans sm:space-x-3.5"
            ><charter-icon class="h-8 w-8 flex-none"></charter-icon>
            <span
              class="hidden select-none text-[38px] font-semibold min-[600px]:inline"
              >Charter</span
            >
          </router-link>
        </div>
        <div class="flex flex-grow basis-1 items-center justify-end">
          <div class="flex basis-[1/2] items-center md:-ml-10 md:space-x-9">
            <!--      <div class="md:-ml-32 flex items-center md:space-x-9">-->
            <!--        <HamburgerMenuIcon class="flex h-9 w-9 sm:hidden" />-->
            <span
              v-if="authStore.token"
              @click="authStore.signOut"
              class="hidden cursor-pointer select-none text-lg md:inline"
              >{{ t("auth.sign-out") }}</span
            >
            <router-link
              v-else
              :to="{ name: 'sign-in' }"
              class="group flex cursor-pointer items-center space-x-3"
            >
              {{ t("auth.sign-in") }}
            </router-link>
            <div
              class="relative flex h-[94px] items-center justify-center px-0 py-0"
            >
              <div class="relative z-0">
                <router-link
                  v-if="authStore.token"
                  :to="{
                    name: 'workspaces',
                  }"
                  class="very magic large button hidden items-center justify-center space-x-3.5 min-[450px]:flex"
                  >Dashboard →</router-link
                >
                <router-link
                  v-else
                  :to="{ name: 'sign-up' }"
                  class="very magic large button hidden items-center justify-center space-x-3.5 min-[450px]:flex"
                >
                  <span>{{ t("landing.navigation.cta") }}</span>
                </router-link>
                <router-link
                  v-if="authStore.token"
                  :to="{
                    name: 'workspaces',
                  }"
                  class="flex items-center justify-center space-x-3.5 min-[450px]:hidden"
                  >Dashboard →</router-link
                >
                <router-link
                  v-else
                  :to="{ name: 'sign-up' }"
                  class="flex items-center justify-center space-x-3.5 min-[450px]:hidden"
                >
                  <span>{{ t("landing.navigation.cta") }}</span>
                </router-link>
              </div>
            </div>
            <div
              class="ml-9 px-3 py-1.5 md:ml-0"
              @click="country_select_open = true"
              v-if="internationalisationStore.country"
            >
              <img
                class="h-auto w-7"
                :src="`/flag/rectangle/${internationalisationStore.country.iso_alpha_2}.svg`"
                type="image/svg+xml"
                :alt="internationalisationStore.country.english_name"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <dialog-wrapper v-model="country_select_open">
      <country-picker-dialog
        @cancel="country_select_open = false"
      ></country-picker-dialog>
    </dialog-wrapper>
  </nav>
  <!--  <div class="h-20 w-full transition-all duration-500 lg:h-28"></div>-->
</template>
