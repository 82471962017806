<script setup lang="ts">
import { CaretDownIcon } from "@radix-icons/vue";
import {
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuRoot,
  DropdownMenuTrigger,
} from "radix-vue";
import { PropType, ref } from "vue";

import { api } from "@/helpers/api.ts";
import { MonthlyUsage } from "@/models/monthly-usage.ts";
import { PreSignedUrl } from "@/models/pre-signed-url.ts";

const isOpen = ref<boolean>(false);

const props = defineProps({
  monthlyUsage: {
    type: Object as PropType<MonthlyUsage>,
    required: true,
  },
});

const downloadInvoice = () => {
  api
    .get(`account/billing/invoice?id=${props.monthlyUsage.invoice_id}`)
    .then((data: unknown) => {
      const link = document.createElement("a");
      link.href = (data as PreSignedUrl).url;
      link.download = "";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });

  isOpen.value = false;
};
const downloadReceipt = () => {
  api
    .get(`account/billing/receipt?id=${props.monthlyUsage.receipt_id}`)
    .then((data: unknown) => {
      const link = document.createElement("a");
      link.href = (data as PreSignedUrl).url;
      link.download = "";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });

  isOpen.value = false;
};
</script>
<template>
  <DropdownMenuRoot
    :open="isOpen"
    @update:open="
      (payload: boolean) => {
        isOpen = payload;
      }
    "
    :modal="false"
  >
    <DropdownMenuTrigger
      class="hover:bg-zinc-100x group relative inline-flex cursor-pointer items-center justify-center rounded-sm outline-none transition-colors"
      aria-label="Customise options"
    >
      <div
        class="flex cursor-pointer items-center space-x-2 text-white/50 transition-all duration-75 hover:text-white"
      >
        <span>Download</span>
        <CaretDownIcon class="h-5 w-auto"></CaretDownIcon>
      </div>
    </DropdownMenuTrigger>

    <DropdownMenuPortal>
      <DropdownMenuContent
        class="data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade flex flex-col space-y-0 rounded-sm border border-zinc-800 bg-black px-1.5 py-2.5 shadow-[0_2px_30px_-15px_rgb(0,0,0,0.2)] outline outline-4 will-change-[opacity,transform]"
        :side-offset="10"
        align="end"
      >
        <DropdownMenuItem
          v-if="!!props.monthlyUsage.invoice_id"
          class="group relative flex cursor-pointer select-none items-center justify-end rounded-sm bg-white px-5 py-2.5 leading-none outline-none transition-colors hover:bg-zinc-100 data-[disabled]:pointer-events-none dark:bg-black dark:hover:bg-zinc-900"
          @click="downloadInvoice"
        >
          Invoice
        </DropdownMenuItem>
        <DropdownMenuItem
          v-if="!!props.monthlyUsage.receipt_id"
          class="group relative flex cursor-pointer select-none items-center justify-end rounded-sm bg-white px-5 py-2.5 leading-none outline-none transition-colors hover:bg-zinc-100 data-[disabled]:pointer-events-none dark:bg-black dark:hover:bg-zinc-900"
          @click="downloadReceipt"
        >
          Receipt
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenuPortal>
  </DropdownMenuRoot>
</template>
