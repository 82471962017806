<script setup lang="ts">
import {
  loadStripe,
  Stripe,
  StripeCardCvcElement,
  StripeCardExpiryElement,
  StripeCardNumberElement,
} from "@stripe/stripe-js";
import { onMounted, PropType, ref } from "vue";

import StyledButton from "@/components/form/styled-button.vue";
import { ButtonType } from "@/enums/button-type.ts";
import { api } from "@/helpers/api.ts";
import { nullsToUndefined } from "@/helpers/nulls-to-undefined.ts";
import { waitFor } from "@/helpers/wait-for.ts";
import { Account } from "@/models/account.ts";
import { PaymentMethod } from "@/models/payment-method.ts";
import { StripeSetupIntent } from "@/models/stripe-setup-intent.ts";
import { useAccountStore } from "@/stores/account.ts";

const emit = defineEmits<{
  (e: "cancel"): void;
  (e: "complete"): void;
}>();

const props = defineProps({
  account: {
    type: Object as PropType<Account>,
    required: true,
  },
});

const accountStore = useAccountStore();

const mounted = ref<boolean>(false);

const stripe = ref<Stripe | undefined>(undefined);
const card_number_element = ref<StripeCardNumberElement | undefined>(undefined);
const card_expiry_element = ref<StripeCardExpiryElement | undefined>(undefined);
const card_cvc_element = ref<StripeCardCvcElement | undefined>(undefined);

const number_valid = ref<boolean>(false);
const expiry_valid = ref<boolean>(false);
const cvc_valid = ref<boolean>(false);

const error_message = ref<string | undefined>(undefined);
const error_message_number = ref<string | undefined>(undefined);
const error_message_expiry = ref<string | undefined>(undefined);
const error_message_cvc = ref<string | undefined>(undefined);

const initStripe = async () => {
  stripe.value =
    (await loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY)) ??
    undefined;
  if (stripe.value === undefined) {
    console.error("Failed to load Stripe");
    return;
  }
  const elements = stripe.value!.elements();
  if (elements === undefined) {
    console.error("Failed to load Stripe elements");
    return;
  }
  const style = {
    base: {
      color: "#ffffff", // Text color
      backgroundColor: "transparent", // Background color of the input
      fontFamily: '"Poppins", sans-serif',
      fontSmoothing: "auto",
      fontSize: "16px",
      lineHeight: "42px",
      "::placeholder": {
        color: "rgba(255,255,255,0.3)", // Placeholder text color
      },
    },
    invalid: {
      color: "#ff002e",
      iconColor: "#ff002e",
    },
  };

  card_number_element.value = elements.create("cardNumber", {
    style: style,
  });
  card_expiry_element.value = elements.create("cardExpiry", {
    style: style,
  });
  card_cvc_element.value = elements.create("cardCvc", {
    style: style,
  });

  await waitFor(() => mounted.value);

  card_number_element.value.mount("#card-number-element");
  card_number_element.value.on("change", (event) => {
    error_message.value = undefined;
    if (event.complete) {
      // valid and complete
      number_valid.value = true;
      error_message_number.value = undefined;
    } else if (event.error) {
      // error
      number_valid.value = false;
      error_message_number.value = event.error.message;
    } else {
      // incomplete
      number_valid.value = false;
      error_message_number.value = undefined;
    }
  });
  card_cvc_element.value.mount("#card-cvc-element");
  card_cvc_element.value.on("change", (event) => {
    error_message.value = undefined;
    if (event.complete) {
      // valid and complete
      cvc_valid.value = true;
      error_message_cvc.value = undefined;
    } else if (event.error) {
      // error
      cvc_valid.value = false;
      error_message_cvc.value = event.error.message;
    } else {
      // incomplete
      cvc_valid.value = false;
      error_message_cvc.value = undefined;
    }
  });
  card_expiry_element.value.mount("#card-expiry-element");
  card_expiry_element.value.on("change", (event) => {
    error_message.value = undefined;
    if (event.complete) {
      // valid and complete
      expiry_valid.value = true;
      error_message_expiry.value = undefined;
    } else if (event.error) {
      // error
      expiry_valid.value = false;
      error_message_expiry.value = event.error.message;
    } else {
      // incomplete
      expiry_valid.value = false;
      error_message_expiry.value = undefined;
    }
  });
};

const setup_intent = ref<StripeSetupIntent | undefined>(undefined);

const createSetupIntent = () => {
  api
    .post(`account/billing/setup-intent`, {
      account_id: props.account.id,
    })
    .then((data: unknown) => {
      setup_intent.value = data as StripeSetupIntent;
    });
};

createSetupIntent();

initStripe();

onMounted(() => {
  mounted.value = true;
});

const submitting = ref<boolean>(false);

const submit = async () => {
  if (
    stripe.value === undefined ||
    card_number_element.value === undefined ||
    card_cvc_element.value === undefined ||
    card_expiry_element.value === undefined
  ) {
    return;
  }

  if (submitting.value) return;

  submitting.value = true;

  await waitFor(() => setup_intent.value !== undefined);

  if (setup_intent.value === undefined) {
    return;
  }

  const { setupIntent: confirmedSetupIntent, error } =
    await stripe.value.confirmCardSetup(setup_intent.value!.client_secret, {
      payment_method: {
        card: card_number_element.value,
      },
    });

  if (error) {
    error_message.value = error.message;
    submitting.value = false;
    return;
  }

  api
    .put(`account/billing/save-payment-method`, {
      account_id: props.account.id,
      stripe_payment_method_id: confirmedSetupIntent.payment_method,
    })
    .then((data: unknown) => {
      if (accountStore.accounts) {
        for (let i = 0; i < accountStore.accounts.length; i++) {
          if (accountStore.accounts[i].id === props.account.id) {
            accountStore.accounts[i].payment_method = nullsToUndefined(
              data,
            ) as PaymentMethod;
            break;
          }
        }
      }
      emit("complete");
      submitting.value = false;
    });
};
const cancel = () => {
  if (submitting.value) return;
  emit("cancel");
};
</script>
<template>
  <div class="flex grow flex-col">
    <div class="grow pb-[36px] pl-[26px] pr-[30px] pt-[14px]">
      <span class="mb-[14px] block text-[18px] font-medium"
        >Add payment method</span
      >
      <span class="block text-[16px] font-light text-white/50"
        >Please enter your card details then click 'Confirm payment
        details'.</span
      >
      <div class="w-full pt-[20px]">
        <div class="flex flex-col space-y-[10px]">
          <div class="flex items-baseline justify-between">
            <span class="ml-[4px] font-medium">Card number</span>
            <span
              class="mr-[8px] text-[14px] text-rose-600"
              v-if="error_message_number"
              >{{ error_message_number }}</span
            >
          </div>
          <div
            id="card-number-element"
            class="h-[40px] rounded-sm bg-white/8 pl-[10px]"
          ></div>
        </div>
        <div class="mt-[20px] grid grid-cols-3 gap-x-[35px]">
          <div class="flex flex-col space-y-[10px]">
            <div class="flex items-baseline justify-between">
              <span class="ml-[4px] font-medium">Expiry date</span>
            </div>
            <div
              id="card-expiry-element"
              class="h-[40px] rounded-sm bg-white/8 pl-[10px]"
            ></div>
            <span
              class="ml-[4px] text-[14px] text-rose-600"
              v-if="error_message_expiry"
              >{{ error_message_expiry }}</span
            >
          </div>
          <div class="flex flex-col space-y-[10px]">
            <div class="flex items-baseline justify-between">
              <span class="ml-[4px] font-medium">CVC</span>
            </div>
            <div
              id="card-cvc-element"
              class="h-[40px] rounded-sm bg-white/8 pl-[10px]"
            ></div>
            <span
              class="ml-[4px] text-[14px] text-rose-600"
              v-if="error_message_cvc"
              >{{ error_message_cvc }}</span
            >
          </div>
        </div>
      </div>
      <div v-if="error_message" class="mt-[30px] font-medium text-rose-600">
        {{ error_message }}
      </div>
    </div>
    <div
      class="fixed bottom-0 right-0 flex h-[60px] w-[675px] items-center justify-between border-0 border-t-[1px] border-white/10 bg-black px-7"
    >
      <div></div>
      <div class="flex items-center space-x-[12px]">
        <styled-button
          @click="cancel"
          :type="ButtonType.REGULAR"
          :submitting="submitting"
          :disabled="submitting"
        >
          Cancel
        </styled-button>
        <styled-button
          @click="submit"
          :type="ButtonType.PRIMARY"
          :submitting="submitting"
          :disabled="
            !stripe ||
            !card_number_element ||
            !card_cvc_element ||
            !card_expiry_element ||
            !number_valid ||
            !expiry_valid ||
            !cvc_valid
          "
        >
          Confirm payment details
        </styled-button>
      </div>
    </div>
  </div>
</template>
