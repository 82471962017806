<script setup lang="ts">
import { computed, PropType } from "vue";
import { RouteLocationRaw } from "vue-router";

import { ButtonSize } from "@/enums/button-size.ts";
import { ButtonType } from "@/enums/button-type.ts";

const emit = defineEmits<{
  (e: "click", event: MouseEvent): void;
}>();
const props = defineProps({
  size: {
    type: String as PropType<ButtonSize>,
    required: false,
    default: ButtonSize.MEDIUM,
  },
  type: {
    type: String as PropType<ButtonType>,
    required: false,
    default: ButtonType.REGULAR,
  },
  href: {
    type: String,
    required: false,
    default: undefined,
  },
  to: {
    type: Object as PropType<RouteLocationRaw>,
    required: false,
    default: undefined,
  },
  submitting: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  target: {
    type: String,
    required: false,
    default: undefined,
  },
});

const click = (e) => {
  if (props.submitting || props.disabled) {
    return;
  }
  emit("click", e);
};

const style = computed<string>(() => {
  const style_: string[] = ["button"];

  switch (props.size) {
    case ButtonSize.MINI:
      style_.push("mini");
      break;
    case ButtonSize.SMALL:
      style_.push("small");
      break;
    case ButtonSize.LARGE:
      style_.push("large");
      break;
  }

  switch (props.type) {
    case ButtonType.PRIMARY:
      style_.push("primary");
      break;
    case ButtonType.DESTRUCTIVE:
      style_.push("destructive");
      break;
    case ButtonType.MAGIC:
      style_.push("magic");
      break;
  }

  if (props.disabled) {
    style_.push("disabled");
  } else {
    if (props.submitting) {
      style_.push("submitting");
    }
  }

  return style_.join(" ");
});
</script>
<template>
  <router-link v-if="props.to" :to="props.to" :class="style" @click="click">
    <slot></slot>
  </router-link>
  <a
    v-else-if="props.href"
    :href="href"
    :class="style"
    @click="click"
    :target="props.target"
  >
    <slot></slot>
  </a>
  <button v-else :class="style" @click="click">
    <slot></slot>
  </button>
</template>
