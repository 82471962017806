<script setup lang="ts">
import { PropType } from "vue";

import { TargetMetric } from "@/enums/target-metric.ts";
import { formatNumber } from "@/helpers/formatting.ts";
import { Campaign } from "@/models/campaign.ts";
import { CampaignGroup } from "@/models/campaign-group.ts";
import { useCurrencyStore } from "@/stores/currency.ts";
import { useWorkspaceStore } from "@/stores/workspace.ts";

const currencyStore = useCurrencyStore();
const workspaceStore = useWorkspaceStore();

const props = defineProps({
  campaign: {
    type: Object as PropType<Campaign>,
    required: true,
  },
  group: {
    type: Object as PropType<CampaignGroup>,
    required: false,
    default: undefined,
  },
});
</script>
<template>
  <div
    v-if="workspaceStore.workspace"
    class="flex flex-col items-end space-y-3 min-[550px]:flex-row min-[550px]:items-center min-[550px]:space-x-5 min-[550px]:space-y-0"
  >
    <div
      :class="`flex flex-col items-end ${props.group?.target_metric === TargetMetric.ROAS ? `min-[550px]:items-end` : `min-[550px]:items-center`}`"
      v-if="
        (props.group?.target_metric === TargetMetric.AD_PROFIT ||
          props.group?.target_metric === TargetMetric.REVENUE ||
          props.group?.target_metric === TargetMetric.ROAS ||
          !props.group) &&
        props.campaign.roas_in_previous_week !== undefined
      "
    >
      <span
        :class="`font-sans text-xs ${props.campaign.roas_in_previous_week >= 1 ? `text-emerald-300/70` : `text-rose-500/70`}`"
        >ROAS</span
      >
      <span
        :class="`font-mono text-sm ${props.campaign.roas_in_previous_week >= 1 ? `text-emerald-300` : `text-rose-500/90`}`"
        >{{ formatNumber(props.campaign.roas_in_previous_week, 2) }}</span
      >
    </div>
    <div
      :class="`flex flex-col items-end ${props.group?.target_metric === TargetMetric.COST_PER_CLICK ? `min-[550px]:items-end` : `min-[550px]:items-center`}`"
      v-if="
        (props.group?.target_metric === TargetMetric.COST_PER_CLICK ||
          props.group?.target_metric === TargetMetric.CLICKS) &&
        props.campaign.daily_cpc_forecast !== undefined
      "
    >
      <span
        :class="`font-sans text-xs ${(props.campaign.daily_clicks_forecast ?? 1 > 0) ? `text-emerald-300/70` : `text-rose-500/70`}`"
        >Cost per click</span
      >
      <span
        :class="`font-mono text-sm ${(props.campaign.daily_clicks_forecast ?? 1 > 0) ? `text-emerald-300` : `text-rose-500/90`}`"
        >{{
          currencyStore.format(
            props.campaign.daily_cpc_forecast,
            workspaceStore.workspace.currency.id,
            props.campaign.currency_id,
          )
        }}</span
      >
    </div>
    <div
      :class="`flex flex-col items-end ${props.group?.target_metric === TargetMetric.COST_PER_MILLE ? `min-[550px]:items-end` : `min-[550px]:items-center`}`"
      v-if="
        (props.group?.target_metric === TargetMetric.COST_PER_MILLE ||
          props.group?.target_metric === TargetMetric.IMPRESSIONS) &&
        props.campaign.daily_cpi_forecast !== undefined
      "
    >
      <span
        :class="`font-sans text-xs ${(props.campaign.daily_impressions_forecast ?? 1 > 0) ? `text-emerald-300/70` : `text-rose-500/70`}`"
        >Cost per mille</span
      >
      <span
        :class="`font-mono text-sm ${(props.campaign.daily_impressions_forecast ?? 1 > 0) ? `text-emerald-300` : `text-rose-500/90`}`"
        >{{
          currencyStore.format(
            props.campaign.daily_cpi_forecast * 1000,
            workspaceStore.workspace.currency.id,
            props.campaign.currency_id,
          )
        }}</span
      >
    </div>
    <div
      :class="`flex flex-col items-end ${props.group?.target_metric === TargetMetric.COST_PER_UNIQUE_MILLE ? `min-[550px]:items-end` : `min-[550px]:items-center`}`"
      v-if="
        (props.group?.target_metric === TargetMetric.COST_PER_UNIQUE_MILLE ||
          props.group?.target_metric === TargetMetric.REACH) &&
        props.campaign.daily_cpr_forecast !== undefined
      "
    >
      <span
        :class="`font-sans text-xs ${(props.campaign.daily_reach_forecast ?? 1 > 0) ? `text-emerald-300/70` : `text-rose-500/70`}`"
        >Cost per unique mille</span
      >
      <span
        :class="`font-mono text-sm ${(props.campaign.daily_reach_forecast ?? 1 > 0) ? `text-emerald-300` : `text-rose-500/90`}`"
        >{{
          currencyStore.format(
            props.campaign.daily_cpr_forecast * 1000,
            workspaceStore.workspace.currency.id,
            props.campaign.currency_id,
          )
        }}</span
      >
    </div>
    <div
      :class="`flex flex-col items-end ${props.group?.target_metric === TargetMetric.COST_PER_UNIQUE_CLICK ? `min-[550px]:items-end` : `min-[550px]:items-center`}`"
      v-if="
        (props.group?.target_metric === TargetMetric.COST_PER_UNIQUE_CLICK ||
          props.group?.target_metric === TargetMetric.UNIQUE_CLICKS) &&
        props.campaign?.daily_cpuc_forecast !== undefined
      "
    >
      <span
        :class="`font-sans text-xs ${(props.campaign.daily_unique_clicks_forecast ?? 1 > 0) ? `text-emerald-300/70` : `text-rose-500/70`}`"
        >Cost per unique click</span
      >
      <span
        :class="`font-mono text-sm ${(props.campaign.daily_unique_clicks_forecast ?? 1 > 0) ? `text-emerald-300` : `text-rose-500/90`}`"
        >{{
          currencyStore.format(
            props.campaign.daily_cpuc_forecast,
            workspaceStore.workspace.currency.id,
            props.campaign.currency_id,
          )
        }}</span
      >
    </div>
    <div
      :class="`flex flex-col items-end ${props.group?.target_metric === TargetMetric.AD_PROFIT ? `min-[550px]:items-end` : `min-[550px]:items-center`}`"
      v-if="
        (props.group?.target_metric === TargetMetric.AD_PROFIT ||
          props.group?.target_metric === TargetMetric.ROAS ||
          !props.group) &&
        props.campaign.daily_ad_profit_forecast !== undefined
      "
    >
      <span
        :class="`font-sans text-xs ${props.campaign.daily_ad_profit_forecast >= 0 ? `text-emerald-300/70` : `text-rose-500/70`}`"
        >Monthly ad profit</span
      >
      <span
        :class="`font-mono text-sm ${props.campaign.daily_ad_profit_forecast >= 0 ? `text-emerald-300` : `text-rose-500/90`}`"
        >{{
          currencyStore.format(
            props.campaign.daily_ad_profit_forecast * (365.25 / 12),
            workspaceStore.workspace.currency.id,
            props.campaign.currency_id,
          )
        }}</span
      >
    </div>
    <div
      :class="`flex flex-col items-end ${props.group?.target_metric === TargetMetric.REVENUE ? `min-[550px]:items-end` : `min-[550px]:items-center`}`"
      v-if="
        props.group?.target_metric === TargetMetric.REVENUE &&
        props.campaign.daily_revenue_forecast !== undefined
      "
    >
      <span
        :class="`font-sans text-xs ${props.campaign.daily_revenue_forecast > props.campaign.effective_daily_budget ? `text-emerald-300/70` : `text-rose-500/70`}`"
        >Monthly ad revenue</span
      >
      <span
        :class="`font-mono text-sm ${props.campaign.daily_revenue_forecast > props.campaign.effective_daily_budget ? `text-emerald-300` : `text-rose-500/90`}`"
        >{{
          currencyStore.format(
            props.campaign.daily_revenue_forecast * (365.25 / 12),
            workspaceStore.workspace.currency.id,
            props.campaign.currency_id,
          )
        }}</span
      >
    </div>
    <div
      :class="`flex flex-col items-end ${props.group?.target_metric === TargetMetric.CLICKS ? `min-[550px]:items-end` : `min-[550px]:items-center`}`"
      v-if="
        props.group?.target_metric === TargetMetric.CLICKS &&
        props.campaign.daily_clicks_forecast !== undefined
      "
    >
      <span
        :class="`font-sans text-xs ${props.campaign.daily_clicks_forecast > 0 ? `text-emerald-300/70` : `text-rose-500/70`}`"
        >Monthly clicks</span
      >
      <span
        :class="`font-mono text-sm ${props.campaign.daily_clicks_forecast > 0 ? `text-emerald-300` : `text-rose-500/90`}`"
        >{{
          formatNumber(props.campaign.daily_clicks_forecast * (365.25 / 12), 0)
        }}</span
      >
    </div>
    <div
      :class="`flex flex-col items-end ${props.group?.target_metric === TargetMetric.UNIQUE_CLICKS ? `min-[550px]:items-end` : `min-[550px]:items-center`}`"
      v-if="
        props.group?.target_metric === TargetMetric.UNIQUE_CLICKS &&
        props.campaign.daily_unique_clicks_forecast !== undefined
      "
    >
      <span
        :class="`font-sans text-xs ${props.campaign.daily_unique_clicks_forecast > 0 ? `text-emerald-300/70` : `text-rose-500/70`}`"
        >Monthly unique clicks</span
      >
      <span
        :class="`font-mono text-sm ${props.campaign.daily_unique_clicks_forecast > 0 ? `text-emerald-300` : `text-rose-500/90`}`"
        >{{
          formatNumber(
            props.campaign.daily_unique_clicks_forecast * (365.25 / 12),
            0,
          )
        }}</span
      >
    </div>
    <div
      :class="`flex flex-col items-end ${props.group?.target_metric === TargetMetric.IMPRESSIONS ? `min-[550px]:items-end` : `min-[550px]:items-center`}`"
      v-if="
        props.group?.target_metric === TargetMetric.IMPRESSIONS &&
        props.campaign.daily_impressions_forecast !== undefined
      "
    >
      <span
        :class="`font-sans text-xs ${props.campaign.daily_impressions_forecast > 0 ? `text-emerald-300/70` : `text-rose-500/70`}`"
        >Monthly impressions</span
      >
      <span
        :class="`font-mono text-sm ${props.campaign.daily_impressions_forecast > 0 ? `text-emerald-300` : `text-rose-500/90`}`"
        >{{
          formatNumber(
            props.campaign.daily_impressions_forecast * (365.25 / 12),
            0,
          )
        }}</span
      >
    </div>
    <div
      :class="`flex flex-col items-end ${props.group?.target_metric === TargetMetric.REACH ? `min-[550px]:items-end` : `min-[550px]:items-center`}`"
      v-if="
        props.group?.target_metric === TargetMetric.REACH &&
        props.campaign.daily_reach_forecast !== undefined
      "
    >
      <span
        :class="`font-sans text-xs ${props.campaign.daily_reach_forecast > 0 ? `text-emerald-300/70` : `text-rose-500/70`}`"
        >Monthly reach</span
      >
      <span
        :class="`font-mono text-sm ${props.campaign.daily_reach_forecast > 0 ? `text-emerald-300` : `text-rose-500/90`}`"
        >{{
          formatNumber(props.campaign.daily_reach_forecast * (365.25 / 12), 0)
        }}</span
      >
    </div>
    <div
      :class="`flex flex-col items-end ${props.group?.target_metric === TargetMetric.COST_PER_PURCHASE ? `min-[550px]:items-end` : `min-[550px]:items-center`}`"
      v-if="
        (props.group?.target_metric === TargetMetric.COST_PER_PURCHASE ||
          props.group?.target_metric ===
            TargetMetric.AVERAGE_CONVERSION_REVENUE) &&
        props.campaign.daily_cpp_forecast !== undefined
      "
    >
      <span
        :class="`font-sans text-xs ${props.campaign.daily_cpp_forecast < (props.campaign.daily_acr_forecast ?? 99999999) ? `text-emerald-300/70` : `text-rose-500/70`}`"
        >Cost per conversion</span
      >
      <span
        :class="`font-mono text-sm ${props.campaign.daily_cpp_forecast < (props.campaign.daily_acr_forecast ?? 99999999) ? `text-emerald-300` : `text-rose-500/90`}`"
        >{{
          currencyStore.format(
            props.campaign.daily_cpp_forecast,
            workspaceStore.workspace.currency.id,
            props.campaign.currency_id,
          )
        }}</span
      >
    </div>
    <div
      :class="`flex flex-col items-end ${props.group?.target_metric === TargetMetric.AVERAGE_CONVERSION_REVENUE ? `min-[550px]:items-end` : `min-[550px]:items-center`}`"
      v-if="
        (props.group?.target_metric === TargetMetric.COST_PER_PURCHASE ||
          props.group?.target_metric ===
            TargetMetric.AVERAGE_CONVERSION_REVENUE) &&
        props.campaign.daily_acr_forecast !== undefined
      "
    >
      <span
        :class="`font-sans text-xs ${(props.campaign.daily_cpp_forecast ?? 0) < (props.campaign.daily_acr_forecast ?? 99999999) ? `text-emerald-300/70` : `text-rose-500/70`}`"
        >Average conversion value</span
      >
      <span
        :class="`font-mono text-sm ${(props.campaign.daily_cpp_forecast ?? 0) < (props.campaign.daily_acr_forecast ?? 99999999) ? `text-emerald-300` : `text-rose-500/90`}`"
        >{{
          currencyStore.format(
            props.campaign.daily_acr_forecast,
            workspaceStore.workspace.currency.id,
            props.campaign.currency_id,
          )
        }}</span
      >
    </div>
  </div>
</template>
