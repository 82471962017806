<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";

import dashboard from "@/assets/docs/dashboard.png";
import { contents, Item, selectedItem } from "@/components/docs/contents.ts";

const route = useRoute();

const page = computed<Item | undefined>(() => selectedItem(route, contents));
</script>
<template>
  <div v-if="page" class="prose prose-invert py-4 pl-5 pr-7">
    <h1>{{ page.title }}</h1>
    <p>
      The dashboard is your main screen in Charter where you can see your
      campaigns and get a quick overview of how your ads are doing. Campaigns
      are presented within their campaign groups, with unassigned campaigns at
      the bottom.
    </p>
    <div class="rounded-md border border-white/30 pl-8 pr-8">
      <img class="object-cover" :src="dashboard" />
    </div>
  </div>
</template>
