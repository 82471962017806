<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";

import { contents, Item, selectedItem } from "@/components/docs/contents.ts";

const route = useRoute();

const page = computed<Item | undefined>(() => selectedItem(route, contents));
</script>
<template>
  <div v-if="page" class="prose prose-invert py-4 pl-5 pr-7">
    <h1>{{ page.title }}</h1>
    <p>Welcome to Charter!</p>

    <p>
      This guide will take you through the process of setting up and using
      Charter. Currently Charter works with Meta and Google, but we'll be adding
      more channels and features as we get feedback from you. If you run into
      any issues or have feedback to give us, drop us Emilie a message on
      emilie@usecharter.io or hop on our live chat.
    </p>

    <p>
      You can use this guide in any way you like, but we recommend following
      through it in order starting with concepts then starting your onboarding.
    </p>
  </div>
</template>
