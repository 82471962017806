<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";

import campaign_group from "@/assets/docs/campaign-group.png";
import { contents, Item, selectedItem } from "@/components/docs/contents.ts";

const route = useRoute();

const page = computed<Item | undefined>(() => selectedItem(route, contents));
</script>
<template>
  <div v-if="page" class="prose prose-invert py-4 pl-5 pr-7">
    <h1>{{ page.title }}</h1>
    <p class="text-xl">
      Charter’s campaign groups allow you to organise campaigns by different
      objectives so Charter's algorithm optimises for each individual objective.
    </p>
    <p>
      To prevent overspending, each campaign group has its own maximum budget
      for Charter to work within, this can be adjusted at any time.
    </p>
  </div>
  <div class="prose-invert flow-root">
    <div
      class="border-l-4 border-[#bf5eff] bg-purple-50 p-4 text-purple-800"
      role="alert"
    >
      <p class="font-bold">Example</p>
      <p>
        You have 2 sets of campaigns, one for brand awareness where you are
        wanting to optimise for Reach, and another for sales where you are
        wanting to optimise for Revenue. You create 2 campaign groups for each
        objective, set the Reach budget to $2,000/month and the Revenue budget
        to $1,000/month.
      </p>
    </div>
  </div>
  <div v-if="page" class="prose prose-invert py-4 pl-5 pr-7">
    <p>
      Campaign groups can include campaigns from different ad platforms - this
      is part of Charter's cross-platform functionality.
    </p>
  </div>
  <div class="prose-invert flow-root">
    <div
      class="border-l-4 border-[#bf5eff] bg-purple-50 p-4 text-purple-800"
      role="alert"
    >
      <p class="font-bold">Example</p>
      <p>
        Within your Reach campaign group you have 2 Meta campaigns and 1 Google
        campaign. Charter identifies that the Google campaign is performing
        better than one of the Meta campaigns for your objective of Reach, so
        moves budget from the Meta campaign to the Google campaign.
      </p>
    </div>
  </div>
  <div v-if="page" class="prose prose-invert py-4 pl-4 pr-4">
    <div class="rounded-md border border-white/30 pl-8 pr-8">
      <img class="object-cover" :src="campaign_group" />
    </div>
  </div>
</template>
