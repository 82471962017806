<script setup lang="ts">
import Tabs from "@/components/landing/workspace/tabs.vue";
import LoadingBar from "@/components/loading-bar.vue";
import BlockGrid from "@/components/workspace/campaigns/block-grid.vue";
import CampaignBlock from "@/components/workspace/campaigns/blocks/block.vue";
import GroupTitle from "@/components/workspace/campaigns/campaign-groups/campaign-group-title.vue";
import TimeframeSelect from "@/components/workspace/campaigns/timeframe-select.vue";
import { useWorkspaceStore } from "@/stores/workspace.ts";

const workspaceStore = useWorkspaceStore();
</script>
<template>
  <div class="relative flex w-full grow flex-col items-center justify-center">
    <div class="w-full" v-if="workspaceStore.workspace">
      <tabs class="mb-7 mt-6">
        <div
          class="relative top-1 flex select-none items-center space-x-12 text-base text-zinc-500"
        >
          <timeframe-select></timeframe-select>
        </div>
      </tabs>
      <div
        class="flex flex-col space-y-8"
        v-if="
          workspaceStore.groupedCampaigns &&
          workspaceStore.groupedCampaigns.length
        "
      >
        <div
          class="pb-7"
          v-for="group_of_campaigns in workspaceStore.groupedCampaigns"
          :key="group_of_campaigns.key"
        >
          <group-title
            :group-of-campaigns="group_of_campaigns"
            v-if="
              workspaceStore.groupedCampaigns.length >= 2 ||
              !!group_of_campaigns.group
            "
          ></group-title>
          <block-grid
            v-if="group_of_campaigns.campaigns.length"
            :landing="true"
            class="mt-4"
          >
            <campaign-block
              v-for="(campaign, i) in group_of_campaigns.campaigns"
              :key="campaign.id"
              :campaign="campaign"
              :group="group_of_campaigns.group"
              :i="i"
            >
            </campaign-block>
          </block-grid>
          <div class="px-9" v-else>
            <span class="opacity-20">(Empty)</span>
          </div>
        </div>
      </div>
    </div>
    <loading-bar v-else></loading-bar>
  </div>
</template>
