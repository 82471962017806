<script setup lang="ts">
import AdChannelIcon from "@/components/ad-channel-icon.vue";
import DialogBody from "@/components/dialog-body.vue";
import DialogTitlebar from "@/components/dialog-titlebar.vue";
import StyledButton from "@/components/form/styled-button.vue";
import Demo from "@/components/landing/demo.vue";
import Step from "@/components/landing/step.vue";
import LoadingBar from "@/components/loading-bar.vue";
import BudgetChangeApprovalDialog from "@/dialogs/budget-change-approval.vue";
import CreateBudgetDialog from "@/dialogs/campaign-groups/settings.vue";
import { AdChannelType } from "@/enums/ad-channel-type.ts";
import { ButtonType } from "@/enums/button-type.ts";
import { useWorkspaceStore } from "@/stores/workspace.ts";

const workspaceStore = useWorkspaceStore();
</script>
<template>
  <div
    class="flex w-auto flex-col space-y-20 rounded-xl lg:space-y-28"
    id="how-it-works"
  >
    <step>
      <template #subtitle> Step 1 </template>
      <template #title> Connect ad channels </template>
      <!--      <template #description>-->
      <!--        <span class="text-balance"-->
      <!--          >Our one-click integrations couldn't be any simpler</span-->
      <!--        >-->
      <!--      </template>-->
      <template #image>
        <div class="dialog-box">
          <dialog-titlebar
            title="Select an ad channel to connect"
          ></dialog-titlebar>
          <dialog-body>
            <div class="flex flex-col space-y-[14px] pt-[6px]">
              <styled-button
                :type="ButtonType.REGULAR"
                class="flex h-[80px] items-center justify-start space-x-[30px] p-[20px] pr-[30px]"
              >
                <div>
                  <div class="relative flex items-center justify-center">
                    <ad-channel-icon
                      :type="AdChannelType.GOOGLE"
                      class="h-10 w-10 cursor-pointer transition-all"
                    />
                  </div>
                </div>
                <span class="font-mono text-lg">Connect to Google Ads</span>
              </styled-button>
              <styled-button
                :type="ButtonType.REGULAR"
                class="flex h-[80px] items-center justify-start space-x-[30px] p-[20px] pr-[30px]"
              >
                <div>
                  <div class="relative flex items-center justify-center">
                    <ad-channel-icon
                      :type="AdChannelType.META"
                      class="h-10 w-10 cursor-pointer transition-all"
                    />
                  </div>
                </div>
                <span class="font-mono text-lg">Connect to Meta Ads</span>
              </styled-button>
            </div>
          </dialog-body>
        </div>
      </template>
    </step>
    <step>
      <template #subtitle> Step 2 </template>
      <template #title> Create a campaign group </template>
      <!--      <template #description>-->
      <!--        Choose an amount and a target metric for the algorithm to control-->
      <!--        budgets within this group to optimise-->
      <!--      </template>-->
      <template #image>
        <div class="dialog-box" v-if="workspaceStore.workspace">
          <create-budget-dialog
            title="Create campaign group"
          ></create-budget-dialog>
        </div>
        <loading-bar class="w-3.5" v-else></loading-bar>
      </template>
    </step>
    <step>
      <template #subtitle> Step 3 </template>
      <template #title> Move campaigns into campaign group </template>
      <!--      <template #description>-->
      <!--        Choose an amount and a target metric for the algorithm to control-->
      <!--        budgets within this group to optimise-->
      <!--      </template>-->
      <template #image>
        <div
          class="dialog-box tall w-full max-w-[1200px]"
          v-if="workspaceStore.workspace"
        >
          <demo></demo>
        </div>
        <loading-bar class="w-3.5" v-else></loading-bar>
      </template>
    </step>
    <step>
      <template #subtitle> Step 4 </template>
      <template #title> Receive budget optimisations </template>
      <!--      <template #description>-->
      <!--        Or allow Charter to run fully automated-->
      <!--      </template>-->
      <template #image>
        <div class="dialog-box" v-if="workspaceStore.workspace">
          <budget-change-approval-dialog
            :run="workspaceStore.workspace.campaign_groups[0].runs[0]"
          ></budget-change-approval-dialog>
        </div>
        <loading-bar class="w-3.5" v-else></loading-bar>
      </template>
    </step>
  </div>
</template>
