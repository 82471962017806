<script setup lang="ts"></script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="120"
    height="76"
    x="0px"
    y="0px"
    viewBox="0 0 120 76"
  >
    <g fill="none" fill-rule="evenodd">
      <path
        fill="#2C97DE"
        d="M111.999 0H8C3.582 0 0 3.59 0 8.008v59.984C0 72.415 3.591 76 8.001 76H112c4.419 0 8.001-3.59 8.001-8.008V8.008C120 3.585 116.409 0 111.999 0Z"
      ></path>
      <g fill="#FFF">
        <path
          d="m95.95 40.419.002.053c.26 6.662 9.375 2.972 10.155 2.474l.72 4.85c-.961.748-3.611 1.819-7.818 1.819-6.953 0-9.983-4.416-9.983-11.37 0-6.955 3.86-11.37 9.201-11.37 7.038 0 9.273 5.152 9.273 10.506v3.038H95.95zm-.049-4.206c.134-2.399.71-4.598 2.607-4.598 2.207 0 2.502 3.297 2.401 4.564l.053.034h-5.06zm-21.02-6.787c1.727-1.743 3.583-2.551 5.405-2.551 6.049 0 7.319 5.635 7.319 11.477 0 6.112-2.959 11.263-9.237 11.263-1.287 0-2.29-.208-2.985-.542v7.029l-6.892 1.14V27.231h6.04l.35 2.195zm.472 4.108c1.867-1.577 5.263-3.208 5.263 4.359 0 7.239-3.362 6.946-5.233 6.048v-8.799c0-.584-.011-1.12-.03-1.608zm-13.115-8.37a3.7 3.7 0 0 0 3.695-3.707 3.7 3.7 0 0 0-3.695-3.707 3.7 3.7 0 0 0-3.695 3.707 3.7 3.7 0 0 0 3.695 3.707zm-3.553 2.067h7.106v21.956h-7.106V27.231zm-13.57 0h6.039l.386 2.639s1.122-3.71 5.511-2.716v6.421c-3.985-.38-5.045 1.304-5.045 1.567v14.045h-6.892V27.231zM42.77 49.103v-5.476c-2.938.698-3.98.249-3.98-2.224v-8.935h3.593l.812-5.167H38.79v-6.343l-5.968.998-.853 5.345-2.132.327-.734 4.84h2.866v10.375c-.074 5.25 3.186 7.935 10.8 6.26ZM22.045 43.618c.75-2.074-2.142-2.679-3.803-3.343-4.998-2-5.156-3.574-5.39-6.67-.232-3.095 1.56-4.884 3.706-5.916 3.333-1.6 9.795-.39 11.1.729l-.729 5.188c-6.674-2.88-7.425-1.164-7.581-.564-.623 2.402 6.773 2.453 8.654 6.35.996 2.062 1.153 5.58-.894 7.763-4.417 4.71-13.347 1.41-14.537.56l.853-5.283c.975.964 7.872 3.26 8.621 1.186Z"
        ></path>
      </g>
    </g>
  </svg>
</template>
