<script setup lang="ts">
import { useRoute, useRouter } from "vue-router";

import bg from "@/assets/bg.png";
import EmailForm from "@/components/auth/email-form.vue";
import PageLayout from "@/components/page-layout.vue";
import { AuthContext } from "@/enums/auth-context.ts";

const route = useRoute();
const router = useRouter();

const proceed = () => {
  if (route.query.to) {
    router.replace(JSON.parse(atob(route.query.to as string)));
  } else {
    router.replace({
      name: "workspaces",
      // query: {
      //   setup: "true",
      // },
    });
  }
};

const back = () => {
  router.push({
    name: "home",
  });
};
</script>

<template>
  <div
    :style="`background-image: url(${bg}); background-size: cover; background-position: top; background-attachment: fixed; background-blend-mode: saturation;`"
  >
    <div class="flex min-h-screen dark:bg-[rgb(0,0,0)]/92">
      <page-layout
        :centered="true"
        :tabs="false"
        :has-back-button="true"
        @back="back"
      >
        <email-form
          class="w-full max-w-[380px]"
          :auth-context="AuthContext.SIGN_UP"
          @proceed="proceed"
        ></email-form
      ></page-layout>
    </div>
  </div>
</template>
