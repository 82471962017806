<script setup lang="ts">
import { useRoute, useRouter } from "vue-router";

import LoadingBar from "@/components/loading-bar.vue";
import { api } from "@/helpers/api.ts";
import { waitFor } from "@/helpers/wait-for.ts";
import { AdChannel } from "@/models/ad-channel.ts";
import { GoogleAdAccountOption } from "@/models/google-ad-account-option.ts";
import { Workspace } from "@/models/workspace.ts";
import { useWorkspaceStore } from "@/stores/workspace.ts";

const route = useRoute();
const router = useRouter();
const workspaceStore = useWorkspaceStore();

type TokenAndAdChannelResponse = {
  access_token: string;
  refresh_token?: string;
  ad_channels: GoogleAdAccountOption[];
};

const state = route.query.state as string;
const [, workspace_id] = state.split("|", 2);
// const [context, workspace_id] = state.split("|", 2);

if (route.query.error) {
  router.replace({
    name: "ad-channels",
    params: {
      workspace: parseInt(workspace_id),
    },
  });
}

const loaded = async () => {
  if (route.query.error) {
    return;
  }
  const code = route.query.code;

  const data = (await api.get(
    `ad-channel/google-options?workspace_id=${workspace_id}&code=${code}&redirect_uri=${window.location.origin}/oauth/google-ad-channel`,
  )) as TokenAndAdChannelResponse;

  if (data.ad_channels.length === 0) {
    alert("No ad account found");
    await router.replace({
      name: "ad-channels",
      params: {
        workspace: parseInt(workspace_id),
      },
      // query: {
      //   setup: context === "setup" ? "true" : undefined,
      // },
    });
  } else if (data.ad_channels.length >= 2) {
    await router.replace({
      name: "ad-channels",
      params: {
        workspace: parseInt(workspace_id),
      },
      query: {
        // setup: context === "setup" ? "true" : undefined,
        option: "google",
        access_token: data.access_token,
        refresh_token: data.refresh_token,
        ad_channels: JSON.stringify(data.ad_channels),
      },
    });
  } else {
    const ad_channel_data: unknown = await api.put(`ad-channel/google-create`, {
      workspace_id: parseInt(workspace_id),
      access_token: data.access_token,
      refresh_token: data.refresh_token,
      ad_channel_id: data.ad_channels[0].id,
      currency_code: data.ad_channels[0].currency_code,
      timezone: data.ad_channels[0].timezone,
      name: data.ad_channels[0].name,
      manager: data.ad_channels[0].manager,
    });
    await waitFor(() => workspaceStore.workspaces !== undefined);
    const workspace = workspaceStore.workspaces!.find(
      (workspace: Workspace) => workspace.id === parseInt(workspace_id),
    )!;
    const first_ad_channel = workspace.ad_channels.length === 0;
    // let adChannelExists = false;
    // if (workspace) {
    //   adChannelExists = workspace.ad_channels.some(
    //     (ad_channel: AdChannel) =>
    //       ad_channel.id === (ad_channel_data as AdChannel).id,
    //   );
    // }
    // if (!adChannelExists) {
    //   // adChannelImportingToast.value = true;
    // }
    workspaceStore.mergeAdChannel(ad_channel_data as AdChannel, workspace);

    await router.replace({
      name: first_ad_channel ? "campaigns" : "ad-channels",
      params: {
        workspace: workspace_id,
      },
      // query: {
      //   setup: context === "setup" ? "true" : undefined,
      //   done: "true",
      // },
    });
  }
};
// const adChannelImportingToast = ref(false);

loaded();

// onMounted(() => {
//   if (workspaceStore.workspaces !== undefined) {
//     loaded();
//   } else {
//     watchOnce(() => workspaceStore.workspaces, loaded);
//   }
// });
</script>
<template>
  <div class="flex grow items-center justify-center">
    <loading-bar></loading-bar>
    <!--    <toast-->
    <!--      v-models="adChannelImportingToast"-->
    <!--      :variant="ToastVariant.SUCCESS"-->
    <!--      title="Ad channel connected"-->
    <!--      description="Your ad channel has been successfully connected and your workspace are now being imported."-->
    <!--      short-description="Your workspace are not being imported."-->
    <!--    ></toast>-->
  </div>
</template>
