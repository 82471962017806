<script setup lang="ts"></script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="120"
    height="76"
    x="0px"
    y="0px"
    viewBox="0 0 120 76"
  >
    <g fill="none" fill-rule="evenodd">
      <path
        fill="#F4F4F4"
        d="M111.999 0H8C3.582 0 0 3.59 0 8.008v59.984C0 72.415 3.591 76 8.001 76H112c4.419 0 8.001-3.59 8.001-8.008V8.008C120 3.585 116.409 0 111.999 0Z"
      ></path>
      <path
        fill="#227FBB"
        d="M2.902 1.838A7.963 7.963 0 0 1 8.002 0h103.997C116.409 0 120 3.585 120 8.008v59.984c0 2.481-1.13 4.7-2.902 6.17L2.902 1.838Z"
      ></path>
      <path fill="#F59D00" d="M0 37.5h60V75L0 37.639z"></path>
      <path fill="#E94B35" d="M0 56h29.72v19z"></path>
      <g fill="#FFF">
        <path
          d="M56.403 27.222c.559-.258 1.284-.957 1.612-1.551.422-.767.595-1.652.596-3.042 0-1.213-.196-2.395-.516-3.103-.295-.654-.957-1.388-1.548-1.714-.759-.42-1.754-.572-3.76-.572h-4.194v10.453h4.129c1.651 0 3.05-.18 3.68-.47Zm-4.51-4.78V20s3.397-.581 3.397 2.444-3.397 2.495-3.397 2.495v-2.495ZM73.077 27.648v-2.736H67.5V23.68h4.943V21.18l-4.937.085-.006-1.214 5.398-.058v-2.799h-8.691v10.453zM86.667 27.659v-2.78h-4.64v-7.656h-3.319v10.436zM95.965 27.584v-7.572h2.97v-2.808h-9.133v2.808h2.847v7.572zM106.026 26.163h3.264l.478 1.458h3.386l-3.791-10.39h-3.17l-3.963 10.39h3.363l.433-1.458Zm.806-2.235.926-2.96.878 2.96h-1.804Z"
        ></path>
      </g>
    </g>
  </svg>
</template>
