<script setup lang="ts"></script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="57"
    height="39"
    x="0px"
    y="0px"
    viewBox="0 0 57 39"
  >
    <rect
      x="0.5"
      y="0.5"
      width="57"
      height="39"
      rx="3.5"
      fill="#F4B6C7"
      stroke="#F3F3F3"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.41299 25.7963H5V15H7.41299V25.7963ZM13.4398 15H11.0784C11.0784 16.9868 10.1885 18.8104 8.63709 20.0034L7.70155 20.7226L11.3264 25.7967H14.3068L10.9714 21.1277C12.5525 19.5116 13.4398 17.3373 13.4398 15ZM17.289 25.7933H15.0102V15.0021H17.289V25.7933ZM24.1766 18.3286V18.8061C23.5616 18.3754 22.8192 18.1223 22.0185 18.1223C19.8993 18.1223 18.1815 19.8857 18.1815 22.0611C18.1815 24.2365 19.8993 26 22.0185 26C22.8192 26 23.5616 25.7469 24.1766 25.3163V25.7933H26.3539V18.3286H24.1766ZM24.1694 22.0611C24.1694 23.1218 23.2861 23.9818 22.1966 23.9818C21.1071 23.9818 20.2238 23.1218 20.2238 22.0611C20.2238 21.0004 21.1071 20.1407 22.1966 20.1407C23.2861 20.1407 24.1694 21.0004 24.1694 22.0611ZM47.1454 18.8061V18.3286H49.3226V25.7933H47.1454V25.3163C46.5304 25.7469 45.788 26 44.9872 26C42.868 26 41.1502 24.2365 41.1502 22.0611C41.1502 19.8857 42.868 18.1223 44.9872 18.1223C45.788 18.1223 46.5304 18.3754 47.1454 18.8061ZM45.1654 23.9818C46.255 23.9818 47.1381 23.1218 47.1381 22.0611C47.1381 21.0004 46.255 20.1407 45.1654 20.1407C44.0758 20.1407 43.1926 21.0004 43.1926 22.0611C43.1926 23.1218 44.0758 23.9818 45.1654 23.9818ZM50.2675 24.5482C50.2675 23.7736 50.8792 23.1457 51.6337 23.1457C52.3882 23.1457 53 23.7736 53 24.5482C53 25.3227 52.3882 25.9507 51.6337 25.9507C50.8792 25.9507 50.2675 25.3227 50.2675 24.5482ZM37.2814 18.1278C36.4117 18.1278 35.5887 18.405 35.0384 19.1697V18.329H32.8706V25.7933H35.065V21.8706C35.065 20.7354 35.8065 20.1796 36.6993 20.1796C37.6562 20.1796 38.2063 20.7663 38.2063 21.8551V25.7933H40.3809V21.0463C40.3809 19.3092 39.0354 18.1278 37.2814 18.1278ZM29.7219 18.3287V19.3009C30.1583 18.7177 30.9715 18.3291 31.8557 18.3291V20.5013L31.8487 20.501L31.8435 20.5008L31.8379 20.5005L31.8298 20.5003C30.9684 20.5003 29.7269 21.1323 29.7269 22.3082V25.7933H27.4928V18.3287H29.7219Z"
      fill="#17120F"
    />
  </svg>
</template>
