<script setup lang="ts"></script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="120"
    height="76"
    x="0px"
    y="0px"
    viewBox="0 0 120 76"
  >
    <g fill="none" fill-rule="evenodd">
      <path
        fill="#1AAF5D"
        d="M111.999 0H8C3.582 0 0 3.59 0 8.008v59.984C0 72.415 3.591 76 8.001 76H112c4.419 0 8.001-3.59 8.001-8.008V8.008C120 3.585 116.409 0 111.999 0Z"
      ></path>
      <path
        fill="#FFF"
        d="M89.794 27.902H88.57a2.243 2.243 0 0 0-2.242 2.243V44.22s-.116 2.447-4.181 2.447c-4.066 0-4.181-2.447-4.181-2.447V30.145a2.243 2.243 0 0 0-2.242-2.243h-2.451a2.243 2.243 0 0 0-2.242 2.242V43.61c0 4.841 3.927 8.768 8.77 8.768h4.691a8.77 8.77 0 0 0 8.77-8.768v-10.2h2.653a1.02 1.02 0 0 0 1.019-1.019v-5.1a1.02 1.02 0 0 0-1.019-1.02h-5.1a1.02 1.02 0 0 0-1.02 1.02v.612zm0 0h1.226a2.244 2.244 0 0 1 2.242 2.242v3.265h-2.449a1.02 1.02 0 0 1-1.019-1.019v-4.488zm6.935-6.118c0-.45.365-.817.817-.817h3.67c.45 0 .816.365.816.817v3.67a.816.816 0 0 1-.817.816h-3.669a.816.816 0 0 1-.817-.817v-3.669zm-3.467-3.674c0-.337.276-.61.61-.61h2.45c.338 0 .611.276.611.61v2.45c0 .338-.276.611-.61.611h-2.45a.612.612 0 0 1-.611-.61v-2.45zM61.089 52.382a36.48 36.48 0 0 1-.463.073c-3.95.513-4.733-2.421-5.419-4.66L51.767 35c-.175-.654.229-1.183.907-1.183h.815c.676 0 1.366.528 1.542 1.182l3.443 12.809c.373.945.85 1.82 1.883 1.82 1.035 0 1.577-.214 2.05-1.977l2.986-12.643c.156-.658.83-1.191 1.507-1.191h.802c.677 0 1.095.534.935 1.19l-3.948 16.17c-1.817 7.135-4.153 8.173-7.927 8.323-1.152-.15-1.745-2.488-.805-2.866 0 0 1.902.013 3.002-.784 1.188-.862 1.693-2.24 1.968-3.026.016-.003.143-.397.162-.442zM47.37 41.363h-6.218a5.406 5.406 0 1 0 0 10.81h4.075a5.406 5.406 0 0 0 5.407-5.405v-7.852c0-5.507-5.92-5.507-7.547-5.507-2.104 0-4.697.318-4.697.318a.93.93 0 0 0-.81.904v1.227c0 .45.363.776.81.729 0 0 2.844-.322 4.539-.322 1.62 0 4.441 0 4.441 2.65v2.448zm-8.362 5.303a2.85 2.85 0 0 1 2.854-2.855h5.508v2.855a2.85 2.85 0 0 1-2.854 2.856h-2.654a2.857 2.857 0 0 1-2.854-2.856zM18 31.37a3.467 3.467 0 0 1 3.469-3.468h7.545a6.73 6.73 0 0 1 6.73 6.729v2.247a6.73 6.73 0 0 1-6.73 6.73h-7.547v7.545a1.02 1.02 0 0 1-1.021 1.02h-1.424A1.021 1.021 0 0 1 18 51.153V31.37zm3.467.795c0-.552.446-1 1.002-1h5.421a4.59 4.59 0 1 1 0 9.179h-6.423v-8.179z"
      ></path>
    </g>
  </svg>
</template>
