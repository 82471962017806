import axios, { InternalAxiosRequestConfig } from "axios";

import {
  clientError,
  connectionAbortedError,
  connectionError,
  connectionTimeoutError,
  serverError,
} from "@/helpers/errors.ts";
import { useAuthStore } from "@/stores/auth.ts";

export const api = {
  get: (url: string, config?: any) => {
    return axios.get(
      !url.split("?", 2)[0].includes("://")
        ? `${import.meta.env.VITE_API_URL}/${url.replace(/^(\/)/, "")}`
        : url,
      config,
    ) as Promise<unknown>;
  },

  post: (url: string, data?: any, config?: any) => {
    return axios.post(
      !url.split("?", 2)[0].includes("://")
        ? `${import.meta.env.VITE_API_URL}/${url.replace(/^(\/)/, "")}`
        : url,
      data ?? {},
      config,
    ) as Promise<unknown>;
  },

  put: (url: string, data?: any, config?: any) => {
    return axios.put(
      !url.split("?", 2)[0].includes("://")
        ? `${import.meta.env.VITE_API_URL}/${url.replace(/^(\/)/, "")}`
        : url,
      data ?? {},
      config,
    ) as Promise<unknown>;
  },

  delete: (url: string, config?: any) => {
    return axios.delete(
      !url.split("?", 2)[0].includes("://")
        ? `${import.meta.env.VITE_API_URL}/${url.replace(/^(\/)/, "")}`
        : url,
      config,
    ) as Promise<unknown>;
  },
};

export class HttpResponseError extends Error {
  status: number;
  method: string;
  url: string;

  data: any;

  constructor(status: number, method: string, url: string, data: any) {
    super(`${method.toUpperCase()} ${url.split("?", 2)[0]}`);
    this.name = `HTTP ${status}`;
    this.status = status;
    this.method = method.toUpperCase();
    this.url = url;
    this.data = data;
  }
}

export class HttpTimeoutError extends Error {
  method: string;
  url: string;
  milliseconds: number;
  constructor(method: string, url: string, milliseconds: number) {
    super(`${method.toUpperCase()} ${url.split("?", 2)[0]}`);
    this.name = `HTTP Timeout`;
    this.method = method.toUpperCase();
    this.url = url;
    this.milliseconds = milliseconds;
  }
}

export class HttpAbortError extends Error {
  method: string;
  url: string;
  milliseconds: number;
  constructor(method: string, url: string, milliseconds: number) {
    super(`${method.toUpperCase()} ${url.split("?", 2)[0]}`);
    this.name = `HTTP Aborted`;
    this.method = method.toUpperCase();
    this.url = url;
    this.milliseconds = milliseconds;
  }
}

export class HttpConnectionError extends Error {
  method: string;
  url: string;
  constructor(method: string, url: string) {
    super(`${method.toUpperCase()} ${url.split("?", 2)[0]}`);
    this.name = `HTTP Connection Refused`;
    this.method = method.toUpperCase();
    this.url = url;
  }
}

axios.interceptors.request.use((request: InternalAxiosRequestConfig) => {
  const authStore = useAuthStore();
  if (
    request.url === import.meta.env.VITE_API_URL ||
    request.url?.startsWith(import.meta.env.VITE_API_URL) + "?" ||
    request.url?.startsWith(import.meta.env.VITE_API_URL) + "/"
  ) {
    if (!request.params) {
      request.params = {};
    }
    request.headers["X-Socket-Id"] = window.Echo.socketId();
    if (authStore.token) {
      request.headers.Authorization = `Bearer ${authStore.token}`;
    }
  }
  if (request.timeout === 0) {
    request.timeout = 30000;
  }
  return request;
});

axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {
    const authStore = useAuthStore();
    if (error.code === "ETIMEDOUT") {
      const exception = new HttpTimeoutError(
        error.config.method,
        error.config.url,
        error.config.timeout,
      );
      // captureException(exception)
      connectionTimeoutError();
      return Promise.reject(exception);
    } else if (error.code === "ECONNABORTED" || error.code === "ERR_CANCELED") {
      const exception = new HttpAbortError(
        error.config.method,
        error.config.url,
        error.config.timeout,
      );
      // captureException(exception)
      connectionAbortedError();
      return Promise.reject(exception);
    } else if (error.code === "ERR_NETWORK") {
      const exception = new HttpConnectionError(
        error.config.method,
        error.config.url,
      );
      // captureException(exception)
      connectionError();
      return Promise.reject(exception);
    }
    if (error.response) {
      if (
        error.config.url === import.meta.env.VITE_API_URL ||
        error.config.url?.startsWith(import.meta.env.VITE_API_URL) + "?" ||
        error.config.url?.startsWith(import.meta.env.VITE_API_URL) + "/"
      ) {
        if (error.response.status === 401) {
          await authStore.signOut();
        } else if (error.response.status === 402) {
          // TODO: reload the workspace details to get up to date subscribed flag
          // - be careful about this firing multiple times, use some type of lock
          // - or always return that resource with a 402 from the backend and load it automatically here?
          // - it could be some response header that is added any time there is a 402
        }
      }
      const exception = new HttpResponseError(
        error.response.status,
        error.config.method.toUpperCase(),
        error.config.url,
        error.response.data,
      );
      if (error.response.status >= 500) {
        serverError(exception.data?.message);
      } else if (![401, 402, 403].includes(error.response.status)) {
        clientError(exception.data?.message);
      }
      return Promise.reject(exception);
    }
    return Promise.reject(error);
  },
);
