<script setup lang="ts">
import { CaretDownIcon } from "@radix-icons/vue";
import dayjs from "dayjs";
import {
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuRoot,
  DropdownMenuTrigger,
} from "radix-vue";
import { PropType, ref } from "vue";

const isOpen = ref<boolean>(false);

const selected = defineModel<dayjs.Dayjs>();

const props = defineProps({
  billedMonths: {
    type: Array as PropType<dayjs.Dayjs[]>,
    required: true,
  },
});
</script>
<template>
  <DropdownMenuRoot
    :open="isOpen"
    @update:open="
      (payload: boolean) => {
        isOpen = payload;
      }
    "
    :modal="false"
  >
    <DropdownMenuTrigger
      class="hover:bg-zinc-100x group relative inline-flex cursor-pointer items-center justify-center rounded-sm outline-none transition-colors"
      aria-label="Customise options"
    >
      <div
        class="flex cursor-pointer items-center space-x-2 text-white/50 transition-all duration-75 hover:text-white"
      >
        <span>{{ selected?.format("MMMM YYYY") }}</span>
        <CaretDownIcon class="h-5 w-auto"></CaretDownIcon>
      </div>
    </DropdownMenuTrigger>

    <DropdownMenuPortal>
      <DropdownMenuContent
        class="data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade flex flex-col space-y-0 rounded-sm border border-zinc-800 bg-black px-1.5 py-2.5 shadow-[0_2px_30px_-15px_rgb(0,0,0,0.2)] outline outline-4 will-change-[opacity,transform]"
        :side-offset="10"
        align="end"
      >
        <DropdownMenuItem
          v-for="billedMonth in props.billedMonths"
          :key="billedMonth.format('MMM YYYY')"
          :value="billedMonth"
          class="group relative flex cursor-pointer select-none items-center justify-end rounded-sm bg-black px-5 py-2.5 leading-none outline-none transition-colors hover:bg-zinc-900 data-[disabled]:pointer-events-none"
          @click="
            () => {
              selected = billedMonth;
            }
          "
        >
          {{ billedMonth.format("MMMM YYYY") }}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenuPortal>
  </DropdownMenuRoot>
</template>
