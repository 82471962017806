import "./css/main.css";
import "./helpers/api.ts";

import { cookieBot } from "@ambitiondev/vue-cookiebot";
import { Preferences } from "@capacitor/preferences";
import { createGtm } from "@gtm-support/vue-gtm";
import Echo from "laravel-echo";
import { createPinia } from "pinia";
import Pusher from "pusher-js";
import { createApp } from "vue";
import { createI18n } from "vue-i18n";

import { api } from "@/helpers/api.ts";
import { Country } from "@/models/country.ts";
import { Language } from "@/models/language.ts";
import tidioPlugin from "@/plugins/tidio";
import router from "@/router";

import en_GB from "../locales/en_GB.json";
import en_US from "../locales/en_US.json";
import App from "./App.vue";

declare global {
  interface Window {
    Pusher: any;

    Echo: any;
    tz: string;
    token: string | undefined;
    campaigns_timeframe: string | undefined;
    chosen_country: Country | undefined;
    chosen_language: Language | undefined;
    remembered_locale: string | undefined;
  }
}

const loadApp = async () => {
  window.Pusher = Pusher;
  window.Echo = new Echo({
    broadcaster: "pusher",
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
    transports: ["ws", "wss"],
    forceTLS: true,
    authorizer: (channel: { name: string }) => {
      return {
        authorize: (socketId: string, callback: any) => {
          api
            .post(`broadcasting/auth`, {
              socket_id: socketId,
              channel_name: channel.name,
            })
            .then((response) => {
              callback(null, response);
            })
            .catch(callback);
        },
      };
    },
  });
  window.Echo.connector.pusher.connection.bind(
    "state_change",
    function (states: { current: string }) {
      if (states.current === "disconnected") {
        window.Echo.connector.pusher.connect();
      }
    },
  );
  window.token = (await Preferences.get({ key: "token" })).value ?? undefined;
  window.campaigns_timeframe =
    (await Preferences.get({ key: "campaigns_timeframe" })).value ?? undefined;

  const chosen_country = (await Preferences.get({ key: "chosen_country" }))
    .value;
  window.chosen_country = chosen_country
    ? (JSON.parse(chosen_country) as Country)
    : undefined;

  const chosen_language = (await Preferences.get({ key: "chosen_language" }))
    .value;

  window.chosen_language = chosen_language
    ? (JSON.parse(chosen_language) as Language)
    : undefined;

  window.remembered_locale =
    (await Preferences.get({ key: "remembered_locale" })).value ?? undefined;

  const app = createApp(App);

  if (import.meta.env.VITE_COOKIEBOT_ID) {
    app.use(cookieBot, {
      cookieBotId: import.meta.env.VITE_COOKIEBOT_ID,
      culture: "en",
    });
  }
  app.use(
    createI18n({
      locale: window.remembered_locale || navigator.language.replace("-", "_"),
      fallbackLocale: "en_US",
      messages: { en_GB, en_US },
      legacy: false,
    }),
  );
  app.use(createPinia());
  app.use(router);
  if (import.meta.env.VITE_GOOGLE_GTM_ID) {
    app.use(
      createGtm({
        id: import.meta.env.VITE_GOOGLE_GTM_ID, // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
        // queryParams: {
        //   // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
        //   gtm_auth: "AB7cDEf3GHIjkl-MnOP8qr",
        //   gtm_preview: "env-4",
        //   gtm_cookies_win: "x",
        // },
        // source: "https://customurl.com/gtm.js", // Add your own serverside GTM script
        defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
        compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
        nonce: "2726c7f26c", // Will add `nonce` to the script tag
        enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
        debug: false, // Whether or not display console logs debugs (optional)
        loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
        vueRouter: router, // Pass the router instance to automatically sync with router (optional)
        ignoredViews: [], // Don't trigger events for specified router names (optional)
        trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
      }),
    );
  }
  app.use(tidioPlugin);
  app.mount("#app");
};

loadApp().then(() => {});
