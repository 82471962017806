<script setup lang="ts">
import { GearIcon } from "@radix-icons/vue";
import { watchOnce } from "@vueuse/core";
import { computed, PropType, ref, watch } from "vue";

import Billing from "@/components/account/billing.vue";
import DialogBody from "@/components/dialog-body.vue";
import DialogTitlebar from "@/components/dialog-titlebar.vue";
import InputSelect from "@/components/form/controls/input-select.vue";
import FormGroup from "@/components/form/form-group.vue";
import StyledButton from "@/components/form/styled-button.vue";
import Scrollview from "@/components/scrollview.vue";
import { AccountTab } from "@/enums/account-tab.ts";
import { ButtonType } from "@/enums/button-type.ts";
import { api } from "@/helpers/api.ts";
import { Account } from "@/models/account.ts";
import { Currency } from "@/models/currency.ts";
import { useAccountStore } from "@/stores/account.ts";
import { useCurrencyStore } from "@/stores/currency.ts";
import { useTimezoneStore } from "@/stores/timezones.ts";
import { useWorkspaceStore } from "@/stores/workspace.ts";

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  initialTab: {
    type: String as PropType<AccountTab>,
    required: false,
    default: undefined,
  },
});
const emit = defineEmits<{
  (e: "close"): void;
  (e: "remove"): void;
}>();
const currencyStore = useCurrencyStore();
const timezoneStore = useTimezoneStore();
const workspaceStore = useWorkspaceStore();
const accountStore = useAccountStore();

const name = ref<string>("");
const currency = ref<Currency | undefined>();
const timezone = ref<string>("");

const reset = () => {
  name.value = account.value?.name || "";
  currency.value = account.value?.currency || undefined;
  timezone.value = account.value?.timezone || "";
};

const initialiseAccount = async () => {
  if (accountStore.account) {
    account.value = accountStore.account;
    reset();
  } else if (accountStore.accounts && accountStore.accounts.length > 0) {
    account.value = accountStore.accounts[0];
    reset();
  }
};
const account = ref<Account | undefined>();
if (accountStore.accounts) {
  initialiseAccount();
} else {
  watchOnce(() => accountStore.account, initialiseAccount);
}

watch(account, () => {
  reset();
});

const tab = ref<AccountTab>(props.initialTab ?? AccountTab.GENERAL);

const submitting = ref<boolean>(false);

type GeneralErrors = {
  name?: string;
  currency?: string;
  timezone?: string;
};
// type BillingErrors = {};
type Errors = {
  general: GeneralErrors;
  // billing: BillingErrors;
};
const errors = ref<Errors>({
  general: {},
  // billing: {},
});

// const hasGeneralTabErrors = () => {
//   return (
//     Object.keys(errors.value.general).filter(
//       (key) => !!errors.value.general[key],
//     ).length >= 1
//   );
// };
// const hasErrors = () => {
//   return hasGeneralTabErrors();
// };
//
// const validate = () => {
//   validateGeneral();
// };
// const validateGeneral = () => {
//   validateName();
// };
const validateName = () => {
  if (!name.value.length) {
    errors.value.general.name = "Please enter a name for the account";
    return;
  }
  errors.value.general.name = undefined;
};

const validateCurrency = () => {
  if (!currency.value) {
    errors.value.general.currency = "Please select a currency";
    return;
  }
  errors.value.general.currency = undefined;
};

const validateTimezone = () => {
  if (!timezone.value.length) {
    errors.value.general.timezone = "Please enter a timezone for the account";
    return;
  }
  // todo: proper validation

  errors.value.general.timezone = undefined;
};

const name_savable = computed<boolean>(() => {
  if (!account.value) {
    return false;
  }
  return (account.value.name ?? "") !== (name.value ?? "");
});
const currency_savable = computed<boolean>(() => {
  if (!account.value) {
    return false;
  }
  return (account.value.currency?.id ?? 0) !== (currency.value?.id ?? 0);
});
const timezone_savable = computed<boolean>(() => {
  if (!account.value) {
    return false;
  }
  return (account.value.timezone ?? "") !== (timezone.value ?? "");
});

const updateName = () => {
  if (!account.value) {
    return;
  }
  if (submitting.value === true) {
    return;
  }
  submitting.value = true;

  // validate
  validateName();

  if (errors.value.general.name) {
    submitting.value = false;
    return;
  }

  // submit
  account.value.name = name.value;
  api
    .put("account/update-name", {
      account_id: account.value.id,
      name: name.value,
    })
    .finally(() => {
      submitting.value = false;
    });
};
const updateCurrency = () => {
  if (!account.value) {
    return;
  }
  if (submitting.value === true) {
    return;
  }
  submitting.value = true;

  // validate
  validateCurrency();

  if (errors.value.general.currency || !currency.value) {
    submitting.value = false;
    return;
  }

  // submit
  account.value.currency = currency.value;
  api
    .put("account/update-currency", {
      account_id: account.value.id,
      currency_id: currency.value.id,
    })
    .finally(() => {
      submitting.value = false;
    });
};
const updateTimezone = () => {
  if (!account.value) {
    return;
  }
  if (submitting.value === true) {
    return;
  }
  submitting.value = true;

  // validate
  validateTimezone();

  if (errors.value.general.timezone) {
    submitting.value = false;
    return;
  }

  // submit
  account.value.timezone = timezone.value;
  api
    .put("account/update-timezone", {
      account_id: account.value.id,
      timezone: timezone.value,
    })
    .finally(() => {
      submitting.value = false;
    });
};
</script>
<template>
  <div>
    <dialog-titlebar
      @close="emit('close')"
      :title="props.title"
      :title-border="true"
      :padding="false"
    >
      <template #right>
        <input-select
          class="input w-[300px] font-normal"
          v-model="account"
          v-if="accountStore.accounts && accountStore.accounts.length >= 2"
        >
          <option
            v-for="account_ in accountStore.accounts"
            :key="account_.id"
            :value="account_"
          >
            {{ account_.name }}
          </option>
        </input-select>
      </template>
    </dialog-titlebar>
    <dialog-body :padding="false" v-if="workspaceStore.workspace">
      <div class="flex w-auto">
        <div
          class="flex w-[210px] flex-col justify-between border-0 border-r-[1px] border-white/10 py-[10px] pl-[3px] pr-[17px]"
        >
          <div class="flex flex-col space-y-[2px]">
            <div
              @click="tab = AccountTab.GENERAL"
              :class="`flex h-[38px] min-w-[202px] cursor-pointer select-none items-center space-x-[14px] rounded-[3px] from-white/10 to-white/4 pl-[20px] pr-[56px]  ${tab === AccountTab.GENERAL ? `bg-gradient-to-r font-semibold` : `font-light hover:bg-white/3`}`"
            >
              <GearIcon
                :class="`h-auto w-[17px] transition-all duration-75 ${tab === AccountTab.GENERAL ? `text-white/50` : `text-white/30`}`"
              ></GearIcon>
              <span>General</span>
            </div>
            <div
              @click="tab = AccountTab.BILLING"
              :class="`flex h-[38px] min-w-[202px] cursor-pointer select-none items-center space-x-[14px] rounded-[3px] from-white/10 to-white/4 pl-[20px] pr-[56px]  ${tab === AccountTab.BILLING ? `bg-gradient-to-r font-semibold` : `font-light hover:bg-white/3`}`"
            >
              <GearIcon
                :class="`h-auto w-[17px] transition-all duration-75 ${tab === AccountTab.BILLING ? `text-white/50` : `text-white/30`}`"
              ></GearIcon>
              <span>Billing</span>
            </div>
          </div>
          <!--          <div class="flex flex-col space-y-[2px] pb-3 pl-6">-->
          <!--            <span-->
          <!--              @click="emit('remove')"-->
          <!--              class="cursor-pointer select-none text-sm text-white/20 transition-all duration-75 hover:text-rose-600"-->
          <!--              >Delete account</span-->
          <!--            >-->
          <!--          </div>-->
        </div>
        <div class="relative flex flex-col">
          <scrollview class="h-[550px] w-[675px]">
            <div v-if="tab === AccountTab.GENERAL">
              <form-group>
                <template #title>Account name</template>
                <template #description
                  >Your account name helps you stay organised if you have access
                  to multiple accounts, and is seen by anyone you give account
                  access to.</template
                >
                <template #controls>
                  <div class="flex items-center justify-between">
                    <input class="w-[300px]" v-model="name" />
                    <styled-button
                      @click="updateName"
                      :submitting="submitting"
                      :type="ButtonType.PRIMARY"
                      :disabled="!name_savable"
                    >
                      Save
                    </styled-button>
                  </div>
                </template>
                <template #error v-if="errors.general.name">{{
                  errors.general.name
                }}</template>
              </form-group>
              <form-group>
                <template #title>Currency</template>
                <template #description
                  >This is the default currency for new workspaces in this
                  account.</template
                >
                <template #controls>
                  <div class="flex items-center justify-between">
                    <input-select
                      class="input w-[300px]"
                      v-model="currency"
                      :readonly="submitting"
                      @change="
                        () => {
                          if (errors.general.currency) {
                            validateCurrency();
                          }
                        }
                      "
                    >
                      <option
                        v-for="currency_ in currencyStore.currencies"
                        :key="currency_.id"
                        :value="currency_"
                      >
                        {{ currency_.name }} ({{ currency_.code }})
                      </option>
                    </input-select>
                    <styled-button
                      @click="updateCurrency"
                      :submitting="submitting"
                      :type="ButtonType.PRIMARY"
                      :disabled="!currency_savable"
                    >
                      Save
                    </styled-button>
                  </div>
                </template>
                <template #error v-if="errors.general.currency">{{
                  errors.general.currency
                }}</template>
              </form-group>
              <form-group>
                <template #title>Timezone</template>
                <template #description
                  >This is the default timezone for new workspaces in this
                  account.</template
                >
                <template #controls>
                  <div class="flex items-center justify-between">
                    <input-select
                      class="input w-[300px]"
                      v-model="timezone"
                      :readonly="submitting"
                      @change="
                        () => {
                          if (errors.general.timezone) {
                            validateTimezone();
                          }
                        }
                      "
                    >
                      <option
                        v-for="timezone_ in timezoneStore.timezones"
                        :key="timezone_.id"
                        :value="timezone_.name"
                      >
                        {{ timezone_.name }}
                      </option>
                    </input-select>
                    <styled-button
                      @click="updateTimezone"
                      :submitting="submitting"
                      :type="ButtonType.PRIMARY"
                      :disabled="!timezone_savable"
                    >
                      Save
                    </styled-button>
                  </div>
                </template>
                <template #error v-if="errors.general.timezone">{{
                  errors.general.timezone
                }}</template>
              </form-group>
            </div>
            <billing
              :account="account"
              v-if="account && tab === AccountTab.BILLING"
            ></billing>
          </scrollview>
        </div>
      </div>
    </dialog-body>
  </div>
</template>
