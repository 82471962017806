<script setup lang="ts"></script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="120"
    height="76"
    x="0px"
    y="0px"
    viewBox="0 0 120 76"
  >
    <g fill="none" fill-rule="evenodd">
      <path
        fill="#9C56B8"
        d="M111.999 0H8C3.582 0 0 3.59 0 8.008v59.984C0 72.415 3.591 76 8.001 76H112c4.419 0 8.001-3.59 8.001-8.008V8.008C120 3.585 116.409 0 111.999 0Z"
      ></path>
      <path
        fill="#FFF"
        d="M81.77 29.932c2.233 0 4.043-1.776 4.043-3.965 0-2.19-1.81-3.967-4.044-3.967-2.23 0-4.04 1.777-4.04 3.967 0 2.19 1.81 3.965 4.04 3.965 0 0-2.23 0 0 0Zm-30.972 2.14c-.182.45-1.496 4.09-4.672 7.862v-16.13l-8.463 1.642v29.236h8.463v-9.037c2.451 3.607 3.662 9.037 3.662 9.037h10.137c-1.003-4.09-5.39-11.63-5.39-11.63 3.937-4.898 5.666-10.115 5.941-10.98h-9.678Zm22.25-.305c-7.58.24-11.429 3.576-11.429 10.209v12.706h8.27V44.32c0-3.972.53-5.674 5.334-5.836v-6.6c-.809-.169-2.175-.117-2.175-.117Zm-49.58 3.525c-1.036-.067-3.436-.219-3.436-2.339 0-2.558 3.456-2.558 4.752-2.558 2.278 0 5.23.66 7.337 1.28 0 0 1.176.406 2.176.823l.093.024v-7.005l-.127-.037c-2.488-.854-5.38-1.676-10.742-1.676C14.276 23.804 11 29.09 11 33.616c0 2.61 1.14 8.75 11.728 9.459.898.054 3.28.188 3.28 2.38 0 1.808-1.95 2.874-5.23 2.874-3.595 0-7.082-.9-9.2-1.752v7.215c3.17.816 6.745 1.223 10.934 1.223 9.04 0 13.088-5 13.088-9.955 0-5.618-4.53-9.268-12.131-9.768 0 0 7.6.5 0 0Zm54.202 19.39h8.203V32.098H77.67v22.584Zm23.649-29.438v29.438h8.204V23.804l-8.204 1.44Zm-11.971 0 8.196-1.44v30.878h-8.196V25.244Z"
      ></path>
    </g>
  </svg>
</template>
